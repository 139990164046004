import "./ToggleSwitch.scss";

type ToggleSwitchProps<T extends string> = {
  leftLabel: string;
  rightLabel: string;
  leftValue: T;
  rightValue: T;
  value: T;
  onChange: (selectedValue: T) => void;
};

export const ToggleSwitch = <T extends string>({
  leftLabel,
  rightLabel,
  leftValue,
  rightValue,
  onChange,
  value,
}: ToggleSwitchProps<T>) => {
  const handleToggle = (selectedValue?: T) => {
    const newValue =
      selectedValue || (value === leftValue ? rightValue : leftValue);

    onChange(newValue);
  };

  return (
    <div
      className={`toggle-container ${
        value === leftValue ? "left-active" : "right-active"
      }`}
    >
      <span
        className={`label ${value === leftValue ? "active" : ""}`}
        onClick={() => handleToggle(leftValue)}
      >
        {leftLabel}
      </span>
      <div className="toggle-track" onClick={() => handleToggle()}>
        <div className="toggle-knob"></div>
      </div>
      <span
        className={`label ${value === rightValue ? "active" : ""}`}
        onClick={() => handleToggle(rightValue)}
      >
        {rightLabel}
      </span>
    </div>
  );
};
