import { useRef, useLayoutEffect } from "react";

export const useContainerHeight = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const topBarRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const adjustContainerHeight = () => {
      if (wrapperRef.current && topBarRef.current && contentRef.current) {
        const { paddingTop, paddingBottom } = getComputedStyle(
          wrapperRef.current
        );
        const padding = parseFloat(paddingTop) + parseFloat(paddingBottom);

        const remainingHeight =
          wrapperRef.current.offsetHeight -
          topBarRef.current.offsetHeight -
          padding;

        contentRef.current.style.maxHeight = `${remainingHeight}px`;
      }
    };

    adjustContainerHeight();
    window.addEventListener("resize", adjustContainerHeight);

    return () => {
      window.removeEventListener("resize", adjustContainerHeight);
    };
  }, []);

  return {
    wrapperRef,
    topBarRef,
    contentRef,
  };
};
