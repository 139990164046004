import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { NavItem } from "../types";

export const useNavItemsLabels = (clientName: string) => {
  const { t } = useTranslation();

  return useMemo<Record<NavItem, string>>(() => {
    return {
      "": t("cubeCockpit.navItems.clientCube", "{{clientName}} Cube", {
        clientName,
      }),
      overview: t("cubeCockpit.navItems.overview", "Cuber Results Overview"),
      "soft-factors": t("cubeCockpit.navItems.softFactors", "Soft Factors"),
      "hard-factors": t("cubeCockpit.navItems.hardFactors", "Hard Factors"),
      vision: t("cubeCockpit.navItems.vision", "Vision & Purpose"),
      team: t("cubeCockpit.navItems.team", "Team"),
      structures: t(
        "cubeCockpit.navItems.structures",
        "Structures & Processes"
      ),
      strategy: t("cubeCockpit.navItems.strategy", "Strategy"),
      leader: t("cubeCockpit.navItems.leader", "Leader"),
      kpis: t("cubeCockpit.navItems.kpis", "KPIs"),
    };
  }, [t, clientName]);
};
