export type LanguageId = "de" | "en";
export const SUPPORTED_LANGUAGES = [
  { id: "de", label: "Deutsch" },
  { id: "en", label: "English" },
  { id: "es", label: "Español" },
  { id: "is", label: "Íslenska" },
];

export const SUPPORTED_LANGUAGES_MAP = SUPPORTED_LANGUAGES.reduce(
  (acc, lang) => ({ ...acc, [lang.id]: lang.label }),
  {} as Record<LanguageId, string>
);
