import "./Score.scss";
import { useTranslation } from "react-i18next";

export type SideScoreProps = {
  percent?: number;
  benchmark?: number;
  title: string;
  color: string;
};

function renderPercentage(percent: number | undefined) {
  return percent !== undefined ? Math.round(percent) : "-";
}

export const SideScore = ({
  percent,
  title,
  color,
  benchmark,
}: SideScoreProps) => {
  const { t } = useTranslation();

  return (
    <div className="cube-side-score" style={{ color }}>
      <div
        className="title"
        dangerouslySetInnerHTML={{ __html: title.replace("&", "&<wbr>") }}
      />
      <div className="percent">{renderPercentage(percent)}%</div>
      {benchmark !== undefined && (
        <div className="benchmark">
          <div className="text">
            {t("cubeCockpit.extendedCube.benchmark", "Benchmark")}:
          </div>
          <div className="percent">{renderPercentage(benchmark)}%</div>
        </div>
      )}
    </div>
  );
};
