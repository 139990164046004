import { GenericObject } from "../../../../../@types";
import { IntersectionValue } from "../../hooks/useHydratedCubeCockpitData/types";
import { intersectionIcons } from "../../icons/icons";
import { CUBE_SIDE_TO_COLOR } from "../Сube/constants";
import "./CubeIntersection.scss";

export const CubeIntersection = ({
  intersectionValues,
  navItemsLabels,
  pageKey,
}: {
  intersectionValues: IntersectionValue[];
  navItemsLabels: GenericObject<string>;
  pageKey: keyof typeof intersectionIcons;
}) => {
  const sides = intersectionValues.map(s => s.side);

  const getSideData = (sideIndex: number) => {
    const sideKey = sides[sideIndex];
    const side = intersectionValues.find(({ side }) => side === sideKey);

    if (!side) {
      return null;
    }

    return {
      title: navItemsLabels[sideKey],
      icon: intersectionIcons[sideKey],
      percent: Math.round(side.percentageValue),
      color: CUBE_SIDE_TO_COLOR[sideKey],
    };
  };

  const side1 = getSideData(0);
  const side2 = getSideData(1);
  const side3 = getSideData(2);
  const side4 = getSideData(3);

  const centerColor = CUBE_SIDE_TO_COLOR[pageKey];

  return (
    <div className="cube-intersection">
      <div className="top">
        <div
          className="side-block"
          style={{
            background: `linear-gradient(180deg, #fff 0%, ${side1?.color} 10%)`,
          }}
        >
          <div className="side-title">{side1?.title}</div>
          <div className="side-icon">{side1?.icon}</div>
        </div>
        <div
          className="percent-block section"
          style={{
            background: `linear-gradient(to bottom, ${side1?.color} 0%, ${centerColor} 100%)`,
          }}
        >
          {side1?.percent}%
        </div>
      </div>
      <div className="middle">
        <div className="left">
          <div
            className="side-block"
            style={{
              background: `linear-gradient(270deg, ${side4?.color} 90%, #fff 100%)`,
            }}
          >
            <div className="side-title">{side4?.title}</div>
            <div className="side-icon">{side4?.icon}</div>
          </div>
          <div
            className="percent-block section"
            style={{
              background: `linear-gradient(90deg, ${side4?.color} 0%, ${centerColor} 100%)`,
            }}
          >
            {side4?.percent}%
          </div>
        </div>
        <div
          className="center"
          style={{
            background: CUBE_SIDE_TO_COLOR[pageKey],
          }}
        >
          <div className="side-block section">{intersectionIcons[pageKey]}</div>
        </div>
        <div className="right">
          <div
            className="percent-block section"
            style={{
              background: `linear-gradient(90deg, ${centerColor} 0%, ${side2?.color} 100%)`,
            }}
          >
            {side2?.percent}%
          </div>
          <div
            className="side-block"
            style={{
              background: `linear-gradient(90deg, ${side2?.color} 90%, #fff 100%)`,
            }}
          >
            <div className="side-icon">{side2?.icon}</div>
            <div className="side-title">{side2?.title}</div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div
          className="percent-block section"
          style={{
            background: `linear-gradient(to top, ${side3?.color} 0%, ${centerColor} 100%)`,
          }}
        >
          {side3?.percent}%
        </div>
        <div
          className="side-block"
          style={{
            background: `linear-gradient(180deg, ${side3?.color} 90%, #fff 100%)`,
          }}
        >
          <div className="side-icon">{side3?.icon}</div>
          <div className="side-title">{side3?.title}</div>
        </div>
      </div>
    </div>
  );
};
