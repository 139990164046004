import { LeaderChart } from "../../components/LeaderChart/LeaderChart";
import { TeamChart } from "../../components/TeamChart/TeamChart";
import { CubeSideName } from "../../components/Сube/types";
import { ValuesPerSide } from "../../hooks/useHydratedCubeCockpitData/types";

export const OverviewPage = ({
  FilterComponent,
  barColor,
  sideValues,
  title,
}: {
  barColor: string;
  FilterComponent: JSX.Element;
  title: string;
  sideValues: Record<CubeSideName, ValuesPerSide>;
}) => {
  return (
    <div className="cb-container">
      <section className="cb-top-bar cb-panel">
        {FilterComponent}
        <div className="cb-top-title">{title}</div>
      </section>
      <div className="cb-row">
        <div className="cb-col cb-panel">
          <TeamChart sideValues={sideValues} barColor={barColor} />
        </div>
        <div className="cb-col cb-panel">
          <LeaderChart sideValues={sideValues} barColor={barColor} />
        </div>
      </div>
    </div>
  );
};
