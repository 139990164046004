import { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToggleSwitch } from "../../components/ToggleSwitch";
import { CubeSideName } from "../../components/Сube/types";
import { ExtendedCube } from "../../components/ExtendedCube";
import { OverviewData, CalculationType } from "../../hooks/useCubeOverviewData";

import "./DetailedCube.scss";

export interface DetailedCubeProps {
  onCubeSideClick: (side?: CubeSideName) => void;
  title: string;
  cubeOverviewData: OverviewData;
}

const CONTAINER_HEIGHT_WITH_SCALE_1 = 480;

export const DetailedCube = ({
  onCubeSideClick,
  title,
  cubeOverviewData,
}: DetailedCubeProps) => {
  const { t } = useTranslation();

  const [scoreCalculationType, setScoreCalculationType] =
    useState<CalculationType>(CalculationType.Benchmark);
  const [scaleFactor, setScaleFactor] = useState<number>();

  useLayoutEffect(() => {
    const container = document.getElementById("detailed-cube-container");
    if (container) {
      const computedStyle = window.getComputedStyle(container);
      const heightOfContainer =
        container.clientHeight -
        parseFloat(computedStyle.paddingTop) -
        parseFloat(computedStyle.paddingBottom);
      const newScaleFactor =
        heightOfContainer / CONTAINER_HEIGHT_WITH_SCALE_1 || 1;
      setScaleFactor(newScaleFactor);
    }
  }, []);

  return (
    <section className="cb-container">
      <div className="harman-cube cb-panel">
        <section className="top-bar">
          <div className="title">{title}</div>
          <ToggleSwitch
            leftLabel={t("cubeCockpit.detailedCubePage.benchmark", "Benchmark")}
            leftValue={CalculationType.Benchmark}
            rightLabel={t(
              "cubeCockpit.detailedCubePage.averageScore",
              "Average Score"
            )}
            rightValue={CalculationType.AverageScore}
            onChange={setScoreCalculationType}
            value={scoreCalculationType}
          />
        </section>
        <section className="center-bar" id="detailed-cube-container">
          {scaleFactor && (
            <ExtendedCube
              scaleFactor={scaleFactor}
              cubeOverviewData={cubeOverviewData}
              scoreCalculationType={scoreCalculationType}
              onCubeSideClick={onCubeSideClick}
              showBenchmark={false}
            />
          )}
        </section>
        <section className="bottom-bar">
          <div className="text">
            {t("cubeCockpit.detailedCubePage.averageScore", "Average Score")}
          </div>
          <div className="percent">{cubeOverviewData.averageScore}%</div>
          <div className="text">
            {t("cubeCockpit.detailedCubePage.benchmark", "Benchmark")}
          </div>
          <div className="percent">{cubeOverviewData.averageBenchmark}%</div>
          <div className="text">=</div>
          <div className="percent result">
            {Math.round(
              cubeOverviewData.averageScore - cubeOverviewData.averageBenchmark
            )}
            %
          </div>
        </section>
      </div>
    </section>
  );
};
