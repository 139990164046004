import { CubeSideName } from "../../components/Сube/types";
import { StatisticItem } from "../../../Cockpit/types";

export interface IntersectionValue {
  side: CubeSideName;
  percentageValue: number;
}

export interface ReportQuestion {
  testSetId: string;
  text: string;
  dbColumn: string;
  entries: {
    [key: string]: number;
  };
  average: number | null;
}

export interface ReportSection {
  choices: {
    key: string;
    name: string;
  }[];
  questions: ReportQuestion[];
}

export interface AggregatedReport {
  averageScore: ReportSection;
  implementationQuality: ReportSection;
  importance: ReportSection;
  intersections: Record<CubeSideName, ReportSection>;
  otherScore: StatisticItem[];
}

export interface ValuesPerSide {
  teamMembersCount: number;
  leadersCount: number;
  totalCount: number;
  teamMembersPercentageScore: number;
  leadersPercentageScore: number;
  averagePercentageScore: number;
  benchmarkPercentageScore: number;
  intersectionValues: IntersectionValue[];
  implementationQualityPercentage: number;
  importancePercentage: number;
  aggregatedReport: AggregatedReport | null;
}

export enum Category {
  Consent = "C",
  Intersection = "X",
  Importance = "I",
  Quality = "Q",
}
