import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClientTestsResponse, LanguageSelectionPageParams } from "./types";
import { API_URLS } from "../../../utils/api-constants";
import { axiosGet } from "../../../utils/queries";
import { Select } from "../Test/components";
import { LanguageId, SUPPORTED_LANGUAGES_MAP } from "../../../constants";
import Button from "../Test/components/button";
import Loader from "../../../components/Loader";
import { CUBE_COCKPIT_URL, PUBLIC_TEST_URL } from "../constants";
import { UPDATE_CURRENT_SESSION_TEST_DATA } from "../../../store/actions";
import { Context } from "../../../store/store";

import "./styles.scss";
import { setFavIcon, tryEmbedCssLink } from "../../../utils/embed-utils";
import { buildUrlWithGetParams } from "../Test/utils";

export interface LanguageSelectionProps {
  isCockpit: boolean;
}

export function LanguageSelection({
  isCockpit = false,
}: LanguageSelectionProps) {
  // @ts-ignore
  const [, dispatch] = useContext(Context);
  const { clientId, authHash } = useParams<LanguageSelectionPageParams>();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [clientTests, setClientTests] = useState<ClientTestsResponse>();
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (clientId) {
      const url = `${API_URLS.CUBE_COCKPIT}/${clientId}/tests`;
      axiosGet<ClientTestsResponse>(url).then(response => {
        setClientTests(response);
        setIsLoading(false);
        if (response.tests.length > 0) {
          const languageId = response.tests[0].languageId;
          setSelectedLanguage(languageId);
          i18n.changeLanguage(languageId);
        }
      });
    }
  }, [clientId, i18n]);

  useEffect(() => {
    if (!isLoading) {
      // skip this page if only one test is available
      if (clientTests?.tests.length === 1) {
        const selectedTestId = clientTests.tests[0]._id;

        if (isCockpit) {
          history.push(
            buildUrlWithGetParams(
              `${CUBE_COCKPIT_URL}/${clientId}/${selectedTestId}`
            )
          );
        } else {
          saveDataAndNavigateToTestPage(selectedTestId);
        }
      }
    }
  }, [clientTests, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let cssLink: HTMLLinkElement | undefined;

    if (clientTests?.client?.cssLink) {
      cssLink = tryEmbedCssLink(clientTests.client.cssLink);
    }

    if (clientTests?.client?.favIconUrl) {
      setFavIcon(clientTests.client.favIconUrl);
    }

    return () => {
      if (cssLink) {
        cssLink.remove();
      }
    };
  }, [clientTests]);

  function saveDataAndNavigateToTestPage(selectedTestId: string) {
    dispatch({
      type: UPDATE_CURRENT_SESSION_TEST_DATA,
      payload: { sessionId: null },
    });

    history.push(`${PUBLIC_TEST_URL}/${selectedTestId}?aKey=${authHash}`);
  }

  function onStartButtonClick() {
    if (selectedLanguage) {
      const selectedTestId = clientTests?.tests.find(
        test => test.languageId === selectedLanguage
      )?._id!;

      if (isCockpit) {
        history.push(
          buildUrlWithGetParams(
            `${CUBE_COCKPIT_URL}/${clientId}/${selectedTestId}`
          )
        );
      } else {
        saveDataAndNavigateToTestPage(selectedTestId);
      }
    }
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="tg_page tg_client-language-selection">
      <div
        className="tg_language-selection-html"
        dangerouslySetInnerHTML={{
          __html: clientTests?.client?.languageSelectionHtml || "",
        }}
      />
      <div className="tg_column">
        <Select
          name={`language-select`}
          value={selectedLanguage}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            const language = e.target.value;
            setSelectedLanguage(language);
            i18n.changeLanguage(language);
          }}
          options={clientTests?.tests.map(test => ({
            value: test.languageId,
            label: SUPPORTED_LANGUAGES_MAP[test.languageId as LanguageId],
          }))}
        />
      </div>
      <div className="tg_column">
        {/*// @ts-ignore*/}
        <Button large onClick={onStartButtonClick}>
          {t("frontend.languageSelection.nextButtonText", "Start")}
        </Button>
      </div>
    </div>
  );
}
