import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export enum AccessLevel {
  Manager = "Manager",
  Admin = "Admin",
  None = "None",
}

export function useAccessLevel(clientId: string, testId: string) {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const key = searchParams.get("key");

  return useMemo(() => {
    try {
      const encodedKey = window.atob(key || "");
      if (encodedKey === clientId) {
        return { level: AccessLevel.Admin, meta: encodedKey };
      } else {
        if (encodedKey.includes(testId)) {
          return { level: AccessLevel.Manager, meta: encodedKey.split(":")[1] };
        }
      }
    } catch {
      return { level: AccessLevel.None, meta: null };
    }

    return { level: AccessLevel.None, meta: null };
  }, [key, clientId, testId]);
}
