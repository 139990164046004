export const API_URLS = {
  TESTS: "/tests",
  SHEETS: "/sheets",
  TEST_SETS: "/test-sets",
  TEST_ENTRIES: "/test-entries",
  PUBLIC_TEST: "/test-public",
  CUBE_COCKPIT: "/cube-cockpit",
  PUBLIC_TEST_ATTRIBUTES: "/test-public/attributes",
  STATIC_SHEETS: "sheets/static",
  REPORTS: "/reports",
  CLIENTS: "/clients",
  GROUPS: "/groups",
  COCKPIT_CONFIG: "/dashboard/{clientId}/config",
  COCKPIT_ENTRIES: "/dashboard/{clientId}/entries",
};
