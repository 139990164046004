import { ValuesPerSide } from "../useHydratedCubeCockpitData/types";
import { CubeSideName } from "../../components/Сube/types";

export enum CalculationType {
  Benchmark = "Benchmark",
  AverageScore = "AverageScore",
}

export type ScoreTypes = keyof Pick<
  ValuesPerSide,
  "averagePercentageScore" | "benchmarkPercentageScore"
>;

export type CalculatedValueType = "percentageValue" | "cubeSideFillArea";

export type CalculatedScoresBySide = Record<
  CalculatedValueType,
  Record<CubeSideName, number>
>;

export interface OverviewData {
  [CalculationType.Benchmark]: CalculatedScoresBySide;
  [CalculationType.AverageScore]: CalculatedScoresBySide;
  averageScore: number;
  averageBenchmark: number;
  benchmarkPerSidePercentageScore: Record<CubeSideName, number>;
}
