import { useTranslation } from "react-i18next";
import { ResultRow } from "../ResultRow/ResultRow";
import "./LeaderChart.scss";
import { Persons } from "../Persons/Persons";
import { ValuesPerSide } from "../../hooks/useHydratedCubeCockpitData/types";
import { CubeSideName } from "../Сube/types";
import {
  calculateAverage,
  calculateMax,
} from "../../hooks/useCubeOverviewData/utils";

export type LeaderChartProps = {
  sideValues: Partial<Record<CubeSideName, ValuesPerSide>>;
  barColor?: string;
  color?: string;
};

export const LeaderChart = ({
  sideValues,
  barColor = "linear-gradient(to bottom, #3c485a, #eaeaea)",
  color = "#3c485a",
}: LeaderChartProps) => {
  const { t } = useTranslation();

  const implementationQualityPercentage = calculateAverage(
    sideValues,
    "implementationQualityPercentage"
  );

  const importancePercentage = calculateAverage(
    sideValues,
    "importancePercentage"
  );

  const leadersCount = calculateMax(sideValues, "leadersCount");

  return (
    <div className="leader-chart">
      <ResultRow
        text={t(
          "cubeCockpit.leaderChart.benchmarkTitle",
          "Leader only perspective"
        )}
        percent={
          leadersCount
            ? implementationQualityPercentage - importancePercentage
            : null
        }
        color={color}
      />
      <div className="score-bar">
        <div className="top-chart-wrap">
          <div className="top-row">
            <div className="left-section">
              <div className="percentage">
                {implementationQualityPercentage}%
              </div>
              <div className="label">
                {t(
                  "cubeCockpit.leaderChart.implementationQuality",
                  "Implementation Quality"
                )}
              </div>
            </div>
            <div className="right-section">
              <div className="percentage">{importancePercentage}%</div>
              <div className="label">
                {t("cubeCockpit.leaderChart.importance", "Importance")}
              </div>
            </div>
          </div>
          <Persons number={leadersCount} />
          <div className="center-row">
            <div className="progress-wrap">
              <div
                className="left-progress"
                style={{
                  height: `calc(100% - ${implementationQualityPercentage}%`,
                }}
              >
                <div className="left-marker"></div>
              </div>
            </div>
            <div className="bar-wrap">
              <div className="bar" style={{ background: barColor }}></div>
            </div>
            <div className="progress-wrap">
              <div
                className="right-progress"
                style={{ height: `calc(100% - ${importancePercentage}%` }}
              >
                <div className="right-marker"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
