import React from "react";
import { MAX_ATTRIBUTES_COUNT } from "../../../constants";
import { Select } from "../index";
import Typography from "../typography";
import { formatSelectOptions } from "./utils";

function Attribute({
  publicTest,
  attribute,
  currentAttributes,
  index,
  onChange,
  isSpecialAttributes,
  isHidden,
}) {
  const label = publicTest[`${attribute}Label`];
  const values = publicTest[`${attribute}Value`];

  if (!(label && values) || isHidden) {
    return null;
  }

  const onSelectChange = ({ target: { value } }) => {
    const newState = {
      [attribute]: value,
    };

    if (isSpecialAttributes) {
      for (let i = index + 1; i <= MAX_ATTRIBUTES_COUNT; i++) {
        newState[`specialAttribute${i}`] = "";
      }
    }

    onChange(newState);
  };

  let options;
  let isDisabled = false;

  if (isSpecialAttributes) {
    const prevAttributeValue =
      currentAttributes[`specialAttribute${index - 1}`];
    isDisabled = index > 1 && !prevAttributeValue;
    options = formatSelectOptions(values).filter(
      option => !option.parent || option.parent === prevAttributeValue
    );
  } else {
    options = formatSelectOptions(values);
  }

  return (
    <div key={`attribute-${index}`} className="tg_attribute">
      <Typography h3>{label}</Typography>
      <Select
        options={options}
        onChange={onSelectChange}
        value={
          currentAttributes[
            isSpecialAttributes
              ? `specialAttribute${index}`
              : `attribute${index}`
          ] || ""
        }
        disabled={isDisabled}
      />
    </div>
  );
}

export default Attribute;
