import * as React from "react";

const DividerIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={32}
    viewBox="0 0 19 32"
    fill="none"
    {...props}
  >
    <rect
      x={9.19531}
      y={15.0078}
      width={14.0312}
      height={0.5}
      transform="rotate(-90 9.19531 15.0078)"
      fill="#3C485A"
      stroke="#3C485A"
      strokeWidth={0.5}
    />
    <mask id="path-2-inside-1_835_2101" fill="white">
      <path d="M0.960938 31.043L0.960937 18.2539C0.960937 16.597 2.30408 15.2539 3.96094 15.2539L15.9297 15.2539C17.5865 15.2539 18.9297 16.597 18.9297 18.2539L18.9297 31.043L0.960938 31.043Z" />
    </mask>
    <path
      d="M-0.0390625 31.043L-0.0390631 18.2539C-0.0390632 16.0448 1.7518 14.2539 3.96094 14.2539L3.96094 16.2539C2.85637 16.2539 1.96094 17.1493 1.96094 18.2539L1.96094 31.043L-0.0390625 31.043ZM15.9297 14.2539C18.1388 14.2539 19.9297 16.0448 19.9297 18.2539L19.9297 31.043L17.9297 31.043L17.9297 18.2539C17.9297 17.1493 17.0343 16.2539 15.9297 16.2539L15.9297 14.2539ZM18.9297 31.043L0.960938 31.043L18.9297 31.043ZM-0.0390631 18.2539C-0.0390632 16.0448 1.7518 14.2539 3.96094 14.2539L15.9297 14.2539C18.1388 14.2539 19.9297 16.0448 19.9297 18.2539L17.9297 18.2539C17.9297 17.1493 17.0343 16.2539 15.9297 16.2539L3.96094 16.2539C2.85637 16.2539 1.96094 17.1493 1.96094 18.2539L-0.0390631 18.2539Z"
      fill="#3C485A"
      mask="url(#path-2-inside-1_835_2101)"
    />
  </svg>
);
export default DividerIcon;
