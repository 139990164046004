import React, { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../../../../store/store";
import AttributesPicker from "../../components/attributes-picker";
import { Button } from "./../../components";
import { getAvailableAttributes, saveAttributes } from "./../../utils";
import "./styles.scss";
import { useTranslation } from "react-i18next";

function TestAttributes() {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const { t } = useTranslation();
  const {
    publicTest,
    testSessionData: { sessionId },
  } = state;

  const [currentAttributes, setCurrentAttributes] = useState({});
  const [canGoNext, setCanGoNext] = useState(false);

  const availableAttributes = useMemo(
    () => getAvailableAttributes(publicTest, false),
    [publicTest]
  );

  const onChangeHandler = attributes => {
    setCanGoNext(availableAttributes.every(attr => !!attributes[attr]));
    setCurrentAttributes(attributes);
  };

  const onFinishTestButtonClick = () => {
    setCanGoNext(false);
    saveAttributes({
      publicTest,
      sessionId,
      currentAttributes,
      state,
      dispatch,
      history,
    });
  };

  return (
    <div className="tg_page page test-attributes-page">
      <div
        className="tg_attributes-selection-html"
        dangerouslySetInnerHTML={{
          __html: publicTest?.attributeSelectionHtml || "",
        }}
      />
      <div className="tg_column">
        <AttributesPicker
          publicTest={publicTest}
          onChangeHandler={onChangeHandler}
          availableAttributes={availableAttributes}
        />
        <Button onClick={onFinishTestButtonClick} large disabled={!canGoNext}>
          {t("frontend.test.showResultsButtonText", "Show Results")}
        </Button>
      </div>
    </div>
  );
}

export default TestAttributes;
