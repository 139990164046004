import { StatisticItem } from "../../../Cockpit/types";
import CloseIcon from "../../icons/CloseIcon";
import { useContainerHeight } from "./useContainerHeight";
import "./Report.scss";
import "./OtherScoreReport.scss";

export const OtherScoreReport = ({
  color,
  statistics,
  title,
  onCloseClick,
}: {
  color: string;
  statistics: StatisticItem[];
  title: string;
  onCloseClick: () => void;
}) => {
  const { wrapperRef, topBarRef, contentRef } = useContainerHeight();

  return (
    <div className="cb-report other-score cb-panel" ref={wrapperRef}>
      <div className="cb-report-top-bar" ref={topBarRef}>
        <div className="cb-top-bar">
          <div className="cb-top-title" style={{ color }}>
            {title}
          </div>
          <div className="cb-top-close">
            <CloseIcon onClick={onCloseClick} />
          </div>
        </div>
      </div>
      <div
        className="cb-report-container"
        ref={contentRef}
        style={{ maxHeight: 0, scrollbarColor: `${color} #f1f1f1` }}
      >
        {[...statistics].map(s => (
          <div key={s.id} className="score-report">
            <div className="title">{s.title}</div>
            {s.choices.map(c => (
              <div key={c.id} className="container">
                <div className="label">{c.title}</div>
                <div className="number">{c.count}</div>
                <div className="number">{c.percentage}%</div>
              </div>
            ))}
            <div className="container sign">
              <div className="label">∑</div>
              <div className="number">{s.totalChoicesSelected}</div>
              <div className="number"></div>
            </div>
            <div className="container sign">
              <div className="label">Ø</div>
              <div className="number">{s.average}</div>
              <div className="number"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
