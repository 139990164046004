import { useEffect } from "react";
import { setFavIcon } from "../../../../utils/embed-utils";

export function useFavIcon(favIconUrl: string | undefined) {
  useEffect(() => {
    if (favIconUrl) {
      setFavIcon(favIconUrl);
    }
  }, [favIconUrl]);
}
