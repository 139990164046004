import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GenericObject } from "../../../../../@types";
import { StatisticItem } from "../../../Cockpit/types";
import { CubeIntersection } from "../../components/CubeIntersection/CubeIntersection";
import { SideReport } from "../../components/Report/SideReport";
import { OtherScoreReport } from "../../components/Report/OtherScoreReport";
import { LeaderChart } from "../../components/LeaderChart/LeaderChart";
import { ResultRow } from "../../components/ResultRow/ResultRow";
import { TeamChart } from "../../components/TeamChart/TeamChart";
import { CubeSideName } from "../../components/Сube/types";
import { hexToRgba } from "../../hooks/useCubeOverviewData/utils";
import {
  AggregatedReport,
  IntersectionValue,
  ReportSection,
  ValuesPerSide,
} from "../../hooks/useHydratedCubeCockpitData/types";
import { BarsIcon, IntersectionIcon } from "../../icons/icons";

export const CubeSidePage = ({
  sideValues,
  color,
  FilterComponent,
  navItemsLabels,
  pageKey,
  aggregatedReport,
}: {
  sideValues: Partial<Record<CubeSideName, ValuesPerSide>>;
  color: string;
  FilterComponent: JSX.Element;
  navItemsLabels: GenericObject<string>;
  pageKey: CubeSideName;
  aggregatedReport: AggregatedReport | null;
}) => {
  const [reportKey, setReportKey] = useState<keyof AggregatedReport>();
  const [intersectionKey, setIntersectionKey] = useState<CubeSideName>();
  const [sideTitle, setSideTitle] = useState<string>();

  const side = sideValues[pageKey];
  const { t } = useTranslation();

  const activeReportData = reportKey
    ? intersectionKey
      ? aggregatedReport?.intersections[intersectionKey]
      : aggregatedReport?.[reportKey]
    : undefined;

  const acgScoreTitle = t("cubeCockpit.teamChart.avgScore", "Average Score");
  const implementationQualityTitle = t(
    "cubeCockpit.teamChart.implementationQuality",
    "Implementation Quality"
  );
  const importanceTitle = t("cubeCockpit.leaderChart.importance", "Importance");
  const otherTitle = t("cubeCockpit.teamChart.other", "Other");

  const reportSharedProps = {
    color,
    title: `${sideTitle} - ${navItemsLabels[pageKey]}`,
    onCloseClick: () => {
      setReportKey(undefined);
      setIntersectionKey(undefined);
      setSideTitle(undefined);
    },
  };

  return (
    <section
      className="cb-side-page cb-container"
      style={{ backgroundColor: color }}
    >
      <section className="cb-top-bar cb-panel">
        {FilterComponent}
        <div className="cb-top-title" style={{ color }}>
          {navItemsLabels[pageKey]}
        </div>
      </section>
      {activeReportData && reportKey ? (
        <div className="cb-row">
          <div className="cb-col">
            {reportKey === "otherScore" ? (
              <OtherScoreReport
                statistics={activeReportData as StatisticItem[]}
                {...reportSharedProps}
              />
            ) : (
              <SideReport
                report={activeReportData as ReportSection}
                {...reportSharedProps}
              />
            )}
          </div>
        </div>
      ) : (
        <section className="cb-row">
          <div className="cb-col cb-panel">
            <TeamChart sideValues={sideValues} color={color} />
            <div
              className="cb-list"
              style={{
                backgroundColor: hexToRgba(color, 0.05),
              }}
            >
              <div
                className="cb-item"
                onClick={() => {
                  setReportKey("averageScore");
                  setSideTitle(acgScoreTitle);
                }}
              >
                <div>{acgScoreTitle}</div>
                <BarsIcon />
              </div>
              {side?.aggregatedReport?.otherScore &&
                side.aggregatedReport.otherScore.length > 0 && (
                  <div
                    className="cb-item"
                    onClick={() => {
                      setReportKey("otherScore");
                      setSideTitle(otherTitle);
                    }}
                  >
                    <div>{otherTitle}</div>
                    <BarsIcon />
                  </div>
                )}
            </div>
          </div>
          {side && (
            <div className="cb-col cb-panel">
              <ResultRow
                color={color}
                text={t(
                  "cubeCockpit.intersection.centerTopTitle",
                  "Cube Side Intersections"
                )}
              />
              <CubeIntersection
                intersectionValues={side.intersectionValues}
                pageKey={pageKey}
                navItemsLabels={navItemsLabels}
              />
              <div
                className="cb-list"
                style={{
                  backgroundColor: hexToRgba(color, 0.05),
                }}
              >
                {side.intersectionValues.map(
                  (iv: IntersectionValue, i: number) => (
                    <div
                      className="cb-item"
                      key={i}
                      onClick={() => {
                        setReportKey("intersections");
                        setIntersectionKey(iv.side);
                        setSideTitle(navItemsLabels[iv.side]);
                      }}
                    >
                      <div>{navItemsLabels[pageKey]}</div>
                      <IntersectionIcon />
                      <div>{navItemsLabels[iv.side]}</div>
                      <BarsIcon />
                    </div>
                  )
                )}
              </div>
            </div>
          )}
          {(side?.leadersCount ?? 0) > 0 && (
            <div className="cb-col cb-panel">
              <LeaderChart sideValues={sideValues} color={color} />
              <div
                className="cb-list"
                style={{
                  backgroundColor: hexToRgba(color, 0.05),
                }}
              >
                <div
                  className="cb-item"
                  onClick={() => {
                    setReportKey("implementationQuality");
                    setSideTitle(implementationQualityTitle);
                  }}
                >
                  <div>{implementationQualityTitle}</div>
                  <BarsIcon />
                </div>
                <div
                  className="cb-item"
                  onClick={() => {
                    setReportKey("importance");
                    setSideTitle(importanceTitle);
                  }}
                >
                  <div>{importanceTitle}</div>
                  <BarsIcon />
                </div>
              </div>
            </div>
          )}
        </section>
      )}
    </section>
  );
};
