import axios, { AxiosResponse, CancelToken } from "axios";
import request from "./api";

export interface AxiosRequestConfig {
  url: string;
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  params?: Record<string, unknown>;
  data?: Record<string, unknown>;
  headers?: Record<string, string>;
  cancelToken?: CancelToken;
}

function createCancellableRequest<TData>(axiosConfig: AxiosRequestConfig) {
  const source = axios.CancelToken.source();

  const requestPromise = request({
    ...axiosConfig,
    cancelToken: source.token,
  });

  const cancellablePromise = requestPromise.then(
    ({ data }: AxiosResponse<TData>) => data
  );

  // @ts-ignore
  cancellablePromise.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };

  return cancellablePromise;
}

export const axiosGet = <TData = any>(
  url: string,
  options: Partial<AxiosRequestConfig> = {}
) => createCancellableRequest<TData>({ url, method: "GET", ...options });
export const axiosPost = <TData = any>(
  url: string,
  options: Partial<AxiosRequestConfig> = {}
) => createCancellableRequest<TData>({ url, method: "POST", ...options });
export const axiosPut = <TData = any>(
  url: string,
  options: Partial<AxiosRequestConfig> = {}
) => createCancellableRequest<TData>({ url, method: "PUT", ...options });
export const axiosPatch = <TData = any>(
  url: string,
  options: Partial<AxiosRequestConfig> = {}
) => createCancellableRequest<TData>({ url, method: "PATCH", ...options });
export const axiosDelete = <TData = any>(
  url: string,
  options: Partial<AxiosRequestConfig> = {}
) => createCancellableRequest<TData>({ url, method: "DELETE", ...options });
