import { useMemo } from "react";
import { CubeSideName } from "../components/Сube/types";
import { NavItem } from "../types";

export function useSelectedSides(subPage: NavItem) {
  return useMemo<CubeSideName[]>(() => {
    switch (subPage) {
      case undefined:
      case "":
        return [];
      case "overview":
        return ["vision", "kpis", "leader", "team", "structures", "strategy"];
      case "soft-factors":
        return ["vision", "team", "leader"];
      case "hard-factors":
        return ["structures", "strategy", "kpis"];
      default:
        return [subPage as CubeSideName];
    }
  }, [subPage]);
}
