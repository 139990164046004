import { useMemo, useState } from "react";
import { CubeSide } from "./components/cube-side";
import { BottomCircle } from "./components/bottom-circle";
import { CubeProps, CubeSideName } from "./types";
import { CUBE_SIDE_NAMES } from "./constants";

const EMPTY_ARRAY: CubeSideName[] = [];

export function Cube({
  scale = 1,
  greyOutOtherSidesOnHover = false,
  values,
  selectedSides = EMPTY_ARRAY,
  onSideClick,
  onSideHover,
}: CubeProps) {
  const [hoveredSide, setHoveredSide] = useState<CubeSideName>();

  const activeSides = useMemo(() => {
    if (greyOutOtherSidesOnHover) {
      if (hoveredSide !== undefined) {
        return [hoveredSide];
      } else {
        return selectedSides;
      }
    } else {
      return selectedSides;
    }
  }, [hoveredSide, selectedSides, greyOutOtherSidesOnHover]);

  function handleSideMouseLeave() {
    if (greyOutOtherSidesOnHover) {
      setHoveredSide(undefined);
    }
    onSideHover?.(undefined);
  }

  function handleSideMouseEnter(side: CubeSideName) {
    if (greyOutOtherSidesOnHover) {
      setHoveredSide(side);
    }
    onSideHover?.(side);
  }

  function handleSideClick(side: CubeSideName) {
    onSideClick?.(side);
  }

  return (
    <svg
      width={273 * scale}
      height={297 * scale}
      viewBox="0 0 273 297"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="cube-sides">
        {CUBE_SIDE_NAMES.map(side => (
          <CubeSide
            key={side}
            side={side}
            scale={(values?.[side] ?? 0) / 100}
            grayOut={!activeSides.includes(side)}
            onMouseEnter={handleSideMouseEnter}
            onMouseLeave={handleSideMouseLeave}
            onClick={handleSideClick}
          />
        ))}
      </g>
      <BottomCircle />
    </svg>
  );
}
