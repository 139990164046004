import PersonIcon from "../../icons/PersonIcon";
import classNames from "classnames";
import "./Persons.scss";

export const Persons = ({
  number,
  small,
  noBorder,
}: {
  number: number;
  small?: boolean;
  noBorder?: boolean;
}) => {
  return (
    <div className={classNames("person", { small, noBorder })}>
      <PersonIcon />
      <span className="number">{number}</span>
    </div>
  );
};
