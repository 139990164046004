import { useMemo } from "react";
import {
  DtoCubeDataResponse,
  DtoEntry,
  DtoTestSet,
} from "../../@types/dto-types";
import {
  filterEntriesByAttributes,
  getValuesPerSide,
  groupEntriesByCubeSide,
  groupTestSetsByCubeSide,
} from "./utils";
import { CUBE_SIDE_NAMES } from "../../components/Сube/constants";
import { CubeSideName } from "../../components/Сube/types";
import { ValuesPerSide } from "./types";
import { SelectedFilters } from "../useFilters";

const EMPTY_ARRAY: unknown[] = [];

export function useHydratedCubeCockpitData(
  data: DtoCubeDataResponse | undefined,
  selectedFilters: SelectedFilters | undefined,
  currentTestId: string
) {
  const testSets = data?.testSets || (EMPTY_ARRAY as DtoTestSet[]);
  const allEntries = data?.allEntries || (EMPTY_ARRAY as DtoEntry[]);

  return useMemo<Record<CubeSideName, ValuesPerSide>>(() => {
    const testSetsByCubeSide = groupTestSetsByCubeSide(testSets);
    const filteredEntries = selectedFilters
      ? filterEntriesByAttributes(allEntries, selectedFilters)
      : allEntries;
    const groupedEntriesByCubeSide = groupEntriesByCubeSide(
      testSetsByCubeSide,
      filteredEntries
    );

    return CUBE_SIDE_NAMES.reduce((acc, sideName) => {
      acc[sideName] = getValuesPerSide(
        sideName,
        testSetsByCubeSide,
        groupedEntriesByCubeSide,
        currentTestId
      );
      return acc;
    }, {} as Record<CubeSideName, ValuesPerSide>);
  }, [testSets, allEntries, selectedFilters, currentTestId]);
}
