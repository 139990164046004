import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AggregatedDashboard from "./AggregatedDashboard";
import Cockpit from "./Cockpit";
import {
  PUBLIC_COCKPIT_URL,
  PUBLIC_GROUP_URL,
  PUBLIC_REPORT_URL,
  PUBLIC_TEST_URL,
  CUSTOM_PUBLIC_GROUP_URL,
  CUSTOM_PUBLIC_REPORT_URL,
  PUBLIC_START_URL,
  CUBE_COCKPIT_URL,
  CUBE_COCKPIT_INDIVIDUAL_REPORT_URL,
} from "./constants";
import CustomReports from "./CustomReports";
import "./index.scss";
import Test from "./Test";
import CubeCockpit from "./CubeCockpit";
import { IndividualReport } from "./CubeCockpit/routes";
import { LanguageSelection } from "./LanguageSelection";

function Frontend() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/tool/" />
      </Route>
      <Route
        path={`${PUBLIC_START_URL}/:clientId/:authHash`}
        component={LanguageSelection}
      />
      <Route
        path={`${PUBLIC_TEST_URL}/:testId/:subPage?/:sessionId?`}
        component={Test}
      />
      <Route
        path={`${PUBLIC_REPORT_URL}/:entityId`}
        render={props => <AggregatedDashboard isGroup={false} {...props} />}
      />
      <Route
        path={`${PUBLIC_GROUP_URL}/:entityId`}
        render={props => <AggregatedDashboard isGroup={true} {...props} />}
      />
      <Route
        path={`${PUBLIC_COCKPIT_URL}/:clientId/:fieldId?`}
        component={Cockpit}
      />
      <Route
        path={`${CUBE_COCKPIT_URL}/:clientId/start`}
        render={() => <LanguageSelection isCockpit />}
      />
      <Route
        path={`${CUBE_COCKPIT_URL}/:clientId/:testId/:subPage?`}
        component={CubeCockpit}
      />
      <Route
        path={`${CUBE_COCKPIT_INDIVIDUAL_REPORT_URL}/:sessionId`}
        component={IndividualReport}
      />
      <Route
        path={`${CUSTOM_PUBLIC_REPORT_URL}/:entityId`}
        render={props => <CustomReports isGroup={false} {...props} />}
      />
      <Route
        path={`${CUSTOM_PUBLIC_GROUP_URL}/:entityId`}
        render={props => <CustomReports isGroup={true} {...props} />}
      />
    </Switch>
  );
}

export default Frontend;
