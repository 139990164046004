import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { identity, pickBy } from "lodash-es";
import { useConfirm } from "material-ui-confirm";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "react-bootstrap";
import Loader from "../../../components/Loader";
import { API_URLS } from "../../../utils/api-constants";
import {
  useClientId,
  useDelete,
  useGet,
  useGetList,
  useQueryParams,
} from "../hooks";
import { formatDateTime } from "../utils";
import "./styles.scss";
import { downloadFile } from "./utils";

function EntriesList() {
  const { t } = useTranslation();
  const clientId = useClientId();
  const [perPageLimit, setPerPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTestSet, setSelectedTestSet] = useState("-1");
  const [showOnlyCompleted, setShowOnlyCompleted] = useState(false);
  const [includeExtraStrings, setIncludeExtraStrings] = useState(false);
  const { testId } = useQueryParams();
  const confirm = useConfirm();

  const [isOutputRowDataAsSession, setIsOutputRowDataAsSession] =
    useState(false);
  const [isExportAsExcel, setIsExportAsExcel] = useState(false);

  const getTestEntries = useGetList([
    API_URLS.TEST_ENTRIES,
    pickBy(
      {
        limit: perPageLimit,
        page: currentPage,
        spreadsheetId: selectedTestSet,
        showOnlyCompleted,
        testId,
        isOutputRowDataAsSession,
        isExportAsExcel,
      },
      identity
    ),
  ]);
  const getTestSets = useGet([API_URLS.TEST_SETS, { unique: true }]);
  const deleteEntry = useDelete(API_URLS.TEST_ENTRIES);
  const deleteEntryBySession = useDelete(`${API_URLS.TEST_ENTRIES}/session`);

  function onEntireExportButtonClick() {
    downloadFile(
      {
        testId,
        spreadsheetId:
          selectedTestSet && selectedTestSet !== "-1" ? selectedTestSet : null,
        showOnlyCompleted,
        includeExtraStrings,
        clientId,
        rowData: isOutputRowDataAsSession ? "session" : "test-set",
        exportType: isExportAsExcel ? "excel" : "csv",
      },
      isExportAsExcel ? "entries.xlsx" : "entries.csv"
    );
  }

  function onSingleExportButtonClick(testEntry) {
    downloadFile({
      testEntryId: testEntry._id,
      sessionId: testEntry.sessionId,
      includeExtraStrings,
      rowData: isOutputRowDataAsSession ? "session" : "test-set",
      exportType: isExportAsExcel ? "excel" : "csv",
    });
  }

  function onDeleteEntryButtonClick(testEntry) {
    confirm({
      title: t("tool.entries.confirmDeletionTitle", "Remove Confirmation"),
      description: t(
        "tool.entries.confirmDeletionDesc",
        "Are you sure you want to delete this entry?"
      ),
    })
      .then(async () => {
        if (isOutputRowDataAsSession) {
          deleteEntryBySession.mutate(testEntry.sessionId, {
            onSuccess: async () => {
              await getTestEntries.refetch();
            },
          });
        } else {
          deleteEntry.mutate(testEntry._id);
        }
      })
      .catch(() => {});
  }

  function handleChangePage(e, page) {
    setCurrentPage(page + 1);
  }

  function handleChangeRowsPerPage({ target: { value } }) {
    setPerPageLimit(value);
  }

  function handleTestSetChange({ target: { value } }) {
    setSelectedTestSet(value);
  }

  function handleShowOnlyCompleted({ target: { checked } }) {
    setShowOnlyCompleted(checked);
  }

  function handleIncludeExtraStrings({ target: { checked } }) {
    setIncludeExtraStrings(checked);
  }

  function handleRowDataChange({ target: { checked } }) {
    setIsOutputRowDataAsSession(checked);

    if (checked) {
      setSelectedTestSet("-1");
    }
  }

  function handleExportTypeChange({ target: { checked } }) {
    setIsExportAsExcel(checked);
  }

  return (
    <Container maxWidth={false} className="list-page entries">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={3}>
          <TextField
            fullWidth
            select
            label={t("tool.entries.filterByTestSet", "Filter By TestSet")}
            value={selectedTestSet}
            onChange={handleTestSetChange}
            disabled={isOutputRowDataAsSession}
          >
            <MenuItem value="-1">
              {t("tool.entries.filter.all", "All")}
            </MenuItem>
            {getTestSets.data?.map(option => (
              <MenuItem key={option.spreadsheetId} value={option.spreadsheetId}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showOnlyCompleted}
                onChange={handleShowOnlyCompleted}
              />
            }
            label={t("tool.entries.filter.completed", "Completed")}
          />
          <FormControlLabel
            control={<Checkbox onChange={handleIncludeExtraStrings} />}
            label={t(
              "tool.entries.includeExtraString",
              "Include Extra Strings"
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Stack spacing={1}>
            <Typography label="top">
              {t("tool.entries.rowData.name", "Row Data:")}
            </Typography>
            <Typography display="inline">
              {t("tool.entries.rowData.testSet", "Test-Set")}
            </Typography>
            <Switch
              checked={isOutputRowDataAsSession}
              onChange={handleRowDataChange}
              inputProps={{
                "aria-label": "Row data: Test-Set ↔︎ Session",
              }}
            />
            <Typography display="inline">
              {t("tool.entries.rowData.session", "Session")}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack spacing={1}>
            <Typography label="top">
              {t("tool.entries.exportType.name", "Export type:")}
            </Typography>
            <Typography display="inline">
              {t("tool.entries.exportType.csv", "CSV")}
            </Typography>
            <Switch
              checked={isExportAsExcel}
              onChange={handleExportTypeChange}
              inputProps={{
                "aria-label": "Export type: CSV ↔︎ Excel",
              }}
            />
            <Typography display="inline">
              {t("tool.entries.exportType.excel", "Excel")}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<GetAppIcon />}
            onClick={onEntireExportButtonClick}
          >
            {isExportAsExcel
              ? t("tool.entries.excelExport", "Excel Export")
              : t("tool.entries.csvExport", "CSV Export")}
          </Button>
        </Grid>
      </Grid>

      {(getTestEntries.isLoading || deleteEntry.isLoading) && <Loader />}
      {getTestEntries.error && (
        <Alert severity="error">
          {t("tool.general.error.dataFetching", "Data fetching error.")}
        </Alert>
      )}
      <TableContainer component={Paper} className="table-container">
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("tool.entries.testName", "Test Name")}</TableCell>
              {!isOutputRowDataAsSession && (
                <TableCell align="right">
                  {t("tool.entries.testSetName", "Test Set Name")}
                </TableCell>
              )}
              <TableCell align="right">
                {t("tool.entries.sessionId", "Sess ID")}
              </TableCell>
              <TableCell align="right">
                {t("tool.entries.clientName", "Client Name")}
              </TableCell>
              <TableCell align="right">
                {t("tool.entries.dateTime", "Date-Time")}
              </TableCell>
              <TableCell align="right">
                {t("tool.entries.actions", "Actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getTestEntries?.data?.docs?.map(testEntry => (
              <TableRow key={testEntry._id}>
                <TableCell component="th" scope="row">
                  {testEntry.testName}
                </TableCell>
                {!isOutputRowDataAsSession && (
                  <TableCell align="right" component="th" scope="row">
                    {testEntry.testSetName}
                  </TableCell>
                )}
                <TableCell align="right">{testEntry.sessionId}</TableCell>
                <TableCell align="right">{testEntry.clientName}</TableCell>
                <TableCell align="right">
                  {formatDateTime(testEntry.updatedAt)}
                </TableCell>
                <TableCell align="right" sx={{ minWidth: 130 }}>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => onSingleExportButtonClick(testEntry)}
                    size="large"
                  >
                    <GetAppIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => onDeleteEntryButtonClick(testEntry)}
                    size="large"
                    sx={{ marginTop: "-1px" }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {getTestEntries?.data && (
        <TablePagination
          labelRowsPerPage={t("tool.entries.rowsPerPage", "Rows per page:")}
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={getTestEntries?.data?.totalDocs}
          rowsPerPage={perPageLimit}
          page={currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Container>
  );
}

export default EntriesList;
