import { pick } from "lodash";
import { useMemo } from "react";
import { useParams } from "react-router";
import { NavLink, Route, Switch, useHistory } from "react-router-dom";
import { GenericObject } from "../../../@types";
import Loader from "../../../components/Loader";
import { AttributeFilters } from "../components/AttributeFilters";
import { Attribute } from "../components/AttributeFilters/types";
import { CUBE_COCKPIT_URL } from "../constants";
import { buildUrlWithGetParams } from "../Test/utils";
import logoSvg from "./assets/logo.svg";
import { Cube } from "./components/Сube";
import { CUBE_SIDE_TO_COLOR } from "./components/Сube/constants";
import { CubeSideName } from "./components/Сube/types";
import {
  CUBE_COCKPIT_URL_PATTERN,
  CUBE_OVERVIEW_NAV_ROUTES,
  CUBE_SIDES_NAV_ROUTES,
} from "./constants";
import {
  AccessLevel,
  SelectedFilters,
  useAccessLevel,
  useFilters,
  useHydratedCubeCockpitData,
  useNavItemsLabels,
  useRawCubeCockpitData,
  useSelectedSides,
  useSetCurrentLanguage,
  useFavIcon,
} from "./hooks";
import {
  CalculationType,
  useCubeOverviewData,
} from "./hooks/useCubeOverviewData";
import { getBarGradient } from "./hooks/useCubeOverviewData/utils";
import { useCurrentTest } from "./hooks/useCurrentTest";
import "./index.scss";
import { DetailedCube } from "./routes";
import { CubeSidePage } from "./routes/CubeSidePage/CubeSidePage";
import { OverviewPage } from "./routes/OverviewPage/OverviewPage";
import { NavItem, RouteParams } from "./types";

const CubeCockpit = () => {
  const { clientId, testId, subPage } = useParams<RouteParams>();
  const currentRouteUrl = `${CUBE_COCKPIT_URL}/${clientId}/${testId}`;
  const history = useHistory();
  const accessLevel = useAccessLevel(clientId, testId);
  const selectedSides = useSelectedSides(subPage);
  const { isLoading, data: rawData } = useRawCubeCockpitData(clientId);
  const navItemsLabels = useNavItemsLabels(rawData?.client.name ?? "");
  const currentTest = useCurrentTest(testId, rawData);
  const {
    setCurrentSingleFilters,
    currentSingleFilters,
    selectedFiltersMultiLanguage,
  } = useFilters(currentTest, rawData?.tests, accessLevel.meta);
  const valuesPerSide = useHydratedCubeCockpitData(
    rawData,
    selectedSides.length > 0 ? selectedFiltersMultiLanguage : undefined,
    currentTest?._id ?? ""
  );
  const cubeOverviewData = useCubeOverviewData(valuesPerSide);
  useSetCurrentLanguage(currentTest?.languageId);
  useFavIcon(rawData?.client?.favIconUrl);

  const selectedFilterValues = useMemo(
    () => Object.values(currentSingleFilters).flat<string[]>(),
    [currentSingleFilters]
  );

  function renderNavItem(key: NavItem, exact = false) {
    return (
      <NavLink
        key={key}
        className="item"
        exact={exact}
        to={buildUrlWithGetParams(`${currentRouteUrl}/${key}`)}
        activeClassName="active"
      >
        {navItemsLabels[key]}
      </NavLink>
    );
  }

  function handleCubeSideClick(side?: CubeSideName) {
    history.push(buildUrlWithGetParams(`${currentRouteUrl}/${side}`));
  }

  function handleFiltersChange(selectedAttributes: GenericObject<Attribute>) {
    const filters = Object.keys(selectedAttributes).reduce<SelectedFilters>(
      (acc, key) => {
        acc[key as any] = selectedAttributes[key].options;
        return acc;
      },
      {}
    );

    setCurrentSingleFilters(filters);
  }

  const FilterComponent = (
    <AttributeFilters
      test={currentTest!}
      onChange={handleFiltersChange}
      selectedValues={selectedFilterValues}
      showLabelInSelect
      disableFirstAttribute={accessLevel.level === AccessLevel.Manager}
    />
  );

  if (accessLevel.level === AccessLevel.None) {
    return <div>Not authorized</div>;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="cube-cockpit">
      <aside className="sidebar">
        <section className="logo-wrap">
          <img src={logoSvg} alt="Logo" className="log" />
        </section>
        {selectedSides.length > 0 && (
          <section className="small-cube-wrap">
            <Cube
              scale={0.9}
              selectedSides={selectedSides}
              values={
                cubeOverviewData[CalculationType.Benchmark].cubeSideFillArea
              }
              greyOutOtherSidesOnHover
              onSideClick={handleCubeSideClick}
            />
          </section>
        )}
        {accessLevel.level === AccessLevel.Admin && (
          <section className="menu-group">{renderNavItem("", true)}</section>
        )}
        <section className="menu-group">
          {renderNavItem("overview")}
          {renderNavItem("soft-factors")}
          {renderNavItem("hard-factors")}
        </section>
        <section className="menu-group">
          {CUBE_SIDES_NAV_ROUTES.map(key => renderNavItem(key))}
        </section>
      </aside>
      <main className="main">
        <Switch>
          <Route
            exact
            path={CUBE_COCKPIT_URL_PATTERN}
            render={() => (
              <DetailedCube
                onCubeSideClick={handleCubeSideClick}
                title={navItemsLabels[""]}
                cubeOverviewData={cubeOverviewData}
              />
            )}
          />
          {CUBE_OVERVIEW_NAV_ROUTES.map(key => (
            <Route
              key={key}
              path={`${CUBE_COCKPIT_URL_PATTERN}/${key}`}
              render={() => (
                <OverviewPage
                  title={navItemsLabels[key as NavItem]}
                  FilterComponent={FilterComponent}
                  sideValues={pick(valuesPerSide, selectedSides)}
                  barColor={getBarGradient(selectedSides)}
                />
              )}
            />
          ))}
          {CUBE_SIDES_NAV_ROUTES.map(key => {
            if (
              key === "" ||
              key === "overview" ||
              key === "soft-factors" ||
              key === "hard-factors"
            )
              return null;

            return (
              <Route
                key={key}
                path={`${CUBE_COCKPIT_URL_PATTERN}/${key}`}
                render={() => (
                  <CubeSidePage
                    pageKey={key}
                    FilterComponent={FilterComponent}
                    sideValues={pick(valuesPerSide, key)}
                    aggregatedReport={valuesPerSide[key]?.aggregatedReport}
                    color={CUBE_SIDE_TO_COLOR[key]}
                    navItemsLabels={navItemsLabels}
                  />
                )}
              />
            );
          })}
        </Switch>
      </main>
    </div>
  );
};

export default CubeCockpit;
