import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export function useSetCurrentLanguage(languageId: string | undefined) {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (languageId) {
      i18n.changeLanguage(languageId);
    }
  }, [i18n, languageId]);
}
