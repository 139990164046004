import { Button, Grid, TextField, Typography, FormLabel } from "@mui/material";
import { FastField, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/Loader";
import { getCurrentUrl } from "../../../utils/urls";
import { CLIENTS_URL } from "../constants";
import TestsDropdown from "./TestsDropdown";
import { toast } from "react-toastify";
import React from "react";

function InnerForm({
  client,
  error,
  loading,
  editMode,
  values,
  setFieldValue,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  function copyToClipboard(e) {
    navigator.clipboard.writeText(e.target.value).then(() => {
      toast.info(t("tool.client.copiedToClipboard", "Copied to Clipboard"));
    });
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={7}>
        <FastField
          name="name"
          as={TextField}
          required
          label={t("tool.clientForm.name", "Name")}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={7}>
        <FastField
          name="cssLink"
          as={TextField}
          label={t("tool.clientForm.cssLink", "CSS Link")}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={7}>
        <FastField
          name="favIconUrl"
          as={TextField}
          label={t("tool.clientForm.favIcon", "Fav Icon URL")}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={7}>
        <FastField
          name="languageSelectionHtml"
          as={TextField}
          label={t(
            "tool.clientForm.languageSelectionHtml",
            "Language selection HTML"
          )}
          multiline
          rows={4}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        {client && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Client ID"
                  variant="outlined"
                  defaultValue={client._id}
                  fullWidth
                  size="small"
                  disabled={true}
                  sx={{ margin: "24px 0 24px" }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{t("tool.clientForm.cockpit", "Cockpit")}</FormLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  defaultValue={getCurrentUrl(
                    `/cube-cockpit/${client._id}/start?key=${window.btoa(
                      client._id
                    )}`
                  )}
                  size="small"
                  placeholder="Cockpit url"
                  onClick={copyToClipboard}
                  sx={{ margin: "0 0 24px" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormLabel>
                  {t("tool.clientForm.participationUrl", "Participation URL")}
                </FormLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  defaultValue={getCurrentUrl(
                    `/start/${client._id}/5eccef52f9`
                  )}
                  size="small"
                  placeholder="Participation url"
                  onClick={copyToClipboard}
                  sx={{ margin: "0 0 24px" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>
                  {t(
                    "tool.clientForm.participationUrlManagers",
                    "Participation URL Managers"
                  )}
                </FormLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  defaultValue={getCurrentUrl(
                    `/start/${client._id}/1d0258c244`
                  )}
                  size="small"
                  placeholder="Managers url"
                  onClick={copyToClipboard}
                  sx={{ margin: "0 0 24px" }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <TestsDropdown
                testIds={values.testIds}
                setFieldValue={setFieldValue}
              />
            </Grid>
          </>
        )}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={loading}
            >
              {editMode
                ? t("tool.addEdit.save", "Save")
                : t("tool.addEdit.create", "Create")}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                history.push(CLIENTS_URL);
              }}
            >
              {t("tool.reportForm.close", "Close")}
            </Button>
          </Grid>
        </Grid>
        {error && (
          <Typography color="secondary">
            {error?.response?.data?.message ??
              t("tool.addEdit.unexpectedError", "Unexpected error occurred")}
          </Typography>
        )}
        {loading && <Loader />}
      </Grid>
    </Grid>
  );
}

function ClientForm({ client, onSubmit, error, loading, editMode }) {
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        name: "",
        cssLink: "",
        testIds: [],
        ...(client &&
          Object.keys(client).reduce(
            (acc, key) => ({
              ...acc,
              [key]: client[key],
            }),
            {}
          )),
      }}
      onSubmit={async values => {
        const res = await onSubmit({
          ...values,
          testIds: values.testIds.filter(Boolean),
        });

        if (res) {
          history.push(`${CLIENTS_URL}/${res._id}`);
        }
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <InnerForm
              client={client}
              error={error}
              loading={loading}
              editMode={editMode}
              values={values}
              setFieldValue={setFieldValue}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default ClientForm;
