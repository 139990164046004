export function BottomCircle() {
  return (
    <g id="bottom-circle" opacity="0.2">
      <path
        id="a4742685"
        d="M47.7242 136.248L47.0969 136.61L53.3598 141.492L53.9864 141.13L47.7242 136.248Z"
        fill="#999999"
      ></path>
      <path
        id="3e8adafc"
        d="M55.6371 131.99L54.9721 132.32L60.676 137.501L61.341 137.171L55.6371 131.99Z"
        fill="#999999"
      ></path>
      <path
        id="f0ac4358"
        d="M64.0685 128.195L63.3686 128.491L68.4787 133.944L69.1785 133.648L64.0685 128.195Z"
        fill="#999999"
      ></path>
      <path
        id="73819194"
        d="M72.8341 124.787L72.103 125.047L76.5894 130.742L77.3197 130.483L72.8341 124.787Z"
        fill="#999999"
      ></path>
      <path
        id="82232365"
        d="M82.0208 121.858L81.2627 122.08L85.099 127.986L85.8571 127.764L82.0208 121.858Z"
        fill="#999999"
      ></path>
      <path
        id="bda32736"
        d="M91.4515 119.381L90.6713 119.564L93.8362 125.647L94.6164 125.463L91.4515 119.381Z"
        fill="#999999"
      ></path>
      <path
        id="84a8767b"
        d="M101.159 117.418L100.36 117.561L102.839 123.787L103.638 123.643L101.159 117.418Z"
        fill="#999999"
      ></path>
      <path
        id="f63e1a24"
        d="M111.043 115.908L110.231 116.012L112.012 122.343L112.825 122.24L111.043 115.908Z"
        fill="#999999"
      ></path>
      <path
        id="63bd2472"
        d="M121.105 114.889L120.284 114.951L121.361 121.355L122.182 121.292L121.105 114.889Z"
        fill="#999999"
      ></path>
      <path
        id="5bfdaeb6"
        d="M131.19 114.401L130.364 114.423L130.729 120.864L131.555 120.842L131.19 114.401Z"
        fill="#999999"
      ></path>
      <path
        id="af3f809e"
        d="M141.351 114.401L140.525 114.382L140.183 120.824L141.009 120.842L141.351 114.401Z"
        fill="#999999"
      ></path>
      <path
        id="5257eed8"
        d="M151.443 114.916L150.621 114.855L149.567 121.263L150.388 121.323L151.443 114.916Z"
        fill="#999999"
      ></path>
      <path
        id="c1d98322"
        d="M161.498 115.893L160.685 115.792L158.925 122.13L159.738 122.231L161.498 115.893Z"
        fill="#999999"
      ></path>
      <path
        id="9d7bfe75"
        d="M171.352 117.434L170.553 117.293L168.095 123.526L168.894 123.668L171.352 117.434Z"
        fill="#999999"
      ></path>
      <path
        id="2f1fafb6"
        d="M181.043 119.408L180.262 119.226L177.116 125.32L177.897 125.5L181.043 119.408Z"
        fill="#999999"
      ></path>
      <path
        id="1a65a47e"
        d="M190.458 121.911L189.699 121.692L185.883 127.61L186.641 127.83L190.458 121.911Z"
        fill="#999999"
      ></path>
      <path
        id="44912013"
        d="M199.64 124.833L198.908 124.575L194.443 130.287L195.174 130.543L199.64 124.833Z"
        fill="#999999"
      ></path>
      <path
        id="1ff52718"
        d="M208.402 128.245L207.702 127.952L202.61 133.422L203.311 133.716L208.402 128.245Z"
        fill="#999999"
      ></path>
      <path
        id="99e17202"
        d="M216.819 132.048L216.152 131.721L210.466 136.921L211.132 137.249L216.819 132.048Z"
        fill="#999999"
      ></path>
      <path
        id="ba19a09a"
        d="M224.698 136.315L224.07 135.954L217.824 140.858L218.452 141.218L224.698 136.315Z"
        fill="#999999"
      ></path>
      <path
        id="02ab864e"
        d="M239.034 145.879L231.749 150.088L232.288 150.508L239.573 146.299L239.034 145.879Z"
        fill="#999999"
      ></path>
      <path
        id="1625f38e"
        d="M245.404 151.147L237.676 154.984L238.168 155.429L245.895 151.593L245.404 151.147Z"
        fill="#999999"
      ></path>
      <path
        id="8cba98de"
        d="M251.107 156.796L242.976 160.236L243.416 160.706L251.547 157.266L251.107 156.796Z"
        fill="#999999"
      ></path>
      <path
        id="bb46a4f1"
        d="M256.249 162.645L247.754 165.664L248.14 166.154L256.634 163.135L256.249 162.645Z"
        fill="#999999"
      ></path>
      <path
        id="12f2617f"
        d="M260.636 168.781L251.827 171.36L252.157 171.869L260.966 169.289L260.636 168.781Z"
        fill="#999999"
      ></path>
      <path
        id="d142e3b8"
        d="M264.394 175.113L255.322 177.243L255.594 177.767L264.665 175.637L264.394 175.113Z"
        fill="#999999"
      ></path>
      <path
        id="a8322045"
        d="M267.357 181.634L258.077 183.31L258.29 183.845L267.57 182.169L267.357 181.634Z"
        fill="#999999"
      ></path>
      <path
        id="d6fee77b"
        d="M269.679 188.239L260.238 189.442L260.391 189.986L269.832 188.783L269.679 188.239Z"
        fill="#999999"
      ></path>
      <path
        id="881582a6"
        d="M271.186 194.979L261.639 195.707L261.732 196.258L271.278 195.53L271.186 194.979Z"
        fill="#999999"
      ></path>
      <path
        id="b7bc086c"
        d="M271.999 201.748L262.399 202.003L262.431 202.558L272.031 202.303L271.999 201.748Z"
        fill="#999999"
      ></path>
      <path
        id="e85a466c"
        d="M272.053 208.529L262.452 208.308L262.422 208.862L272.023 209.084L272.053 208.529Z"
        fill="#999999"
      ></path>
      <path
        id="ab20c86f"
        d="M271.319 215.33L261.77 214.631L261.679 215.183L271.228 215.881L271.319 215.33Z"
        fill="#999999"
      ></path>
      <path
        id="2af813ee"
        d="M269.915 222.038L260.471 220.866L260.32 221.411L269.764 222.583L269.915 222.038Z"
        fill="#999999"
      ></path>
      <path
        id="e28d6dc0"
        d="M267.658 228.712L258.371 227.072L258.159 227.608L267.446 229.249L267.658 228.712Z"
        fill="#999999"
      ></path>
      <path
        id="98d16c46"
        d="M264.741 235.244L255.663 233.141L255.393 233.666L264.47 235.768L264.741 235.244Z"
        fill="#999999"
      ></path>
      <path
        id="3eda9846"
        d="M261.063 241.585L252.246 239.033L251.918 239.542L260.734 242.095L261.063 241.585Z"
        fill="#999999"
      ></path>
      <path
        id="7443f752"
        d="M256.75 247.74L248.244 244.752L247.86 245.244L256.365 248.232L256.75 247.74Z"
        fill="#999999"
      ></path>
      <path
        id="6219f155"
        d="M251.707 253.648L243.561 250.24L243.123 250.71L251.269 254.119L251.707 253.648Z"
        fill="#999999"
      ></path>
      <path
        id="29dcb933"
        d="M246.062 259.313L238.319 255.505L237.83 255.952L245.573 259.76L246.062 259.313Z"
        fill="#999999"
      ></path>
      <path
        id="f8cfbe0e"
        d="M239.789 264.636L232.489 260.452L231.952 260.874L239.251 265.058L239.789 264.636Z"
        fill="#999999"
      ></path>
      <path
        id="039ccdf2"
        d="M218.678 269.806L218.051 270.167L224.314 275.049L224.941 274.687L218.678 269.806Z"
        fill="#999999"
      ></path>
      <path
        id="50ca012f"
        d="M211.349 273.785L210.684 274.115L216.388 279.296L217.053 278.966L211.349 273.785Z"
        fill="#999999"
      ></path>
      <path
        id="567c208f"
        d="M203.565 277.353L202.865 277.649L207.975 283.102L208.675 282.806L203.565 277.353Z"
        fill="#999999"
      ></path>
      <path
        id="11344fd1"
        d="M195.433 280.579L194.702 280.839L199.188 286.535L199.919 286.275L195.433 280.579Z"
        fill="#999999"
      ></path>
      <path
        id="28e51b18"
        d="M186.952 283.292L186.194 283.514L190.03 289.42L190.788 289.197L186.952 283.292Z"
        fill="#999999"
      ></path>
      <path
        id="270cd3a1"
        d="M178.19 285.654L177.41 285.837L180.575 291.92L181.355 291.736L178.19 285.654Z"
        fill="#999999"
      ></path>
      <path
        id="b0aedea6"
        d="M169.168 287.544L168.37 287.688L170.849 293.913L171.648 293.769L169.168 287.544Z"
        fill="#999999"
      ></path>
      <path
        id="b1661601"
        d="M159.996 288.987L159.184 289.09L160.964 295.423L161.777 295.319L159.996 288.987Z"
        fill="#999999"
      ></path>
      <path
        id="cb23680b"
        d="M150.692 289.933L149.871 289.995L150.948 296.399L151.769 296.336L150.692 289.933Z"
        fill="#999999"
      ></path>
      <path
        id="1a691241"
        d="M141.302 290.441L140.475 290.462L140.841 296.903L141.668 296.881L141.302 290.441Z"
        fill="#999999"
      ></path>
      <path
        id="b0c65658"
        d="M131.881 290.46L131.055 290.44L130.71 296.882L131.536 296.901L131.881 290.46Z"
        fill="#999999"
      ></path>
      <path
        id="ab0714ac"
        d="M122.448 290.045L121.627 289.985L120.572 296.393L121.393 296.452L122.448 290.045Z"
        fill="#999999"
      ></path>
      <path
        id="412a1b84"
        d="M113.105 289.17L112.292 289.068L110.531 295.406L111.343 295.508L113.105 289.17Z"
        fill="#999999"
      ></path>
      <path
        id="2f0a1230"
        d="M103.94 287.78L103.141 287.639L100.683 293.872L101.482 294.014L103.94 287.78Z"
        fill="#999999"
      ></path>
      <path
        id="4893338f"
        d="M94.9073 285.985L94.1256 285.804L90.9814 291.898L91.763 292.079L94.9073 285.985Z"
        fill="#999999"
      ></path>
      <path
        id="420cafd0"
        d="M86.0762 283.708L85.3173 283.488L81.5017 289.407L82.2605 289.627L86.0762 283.708Z"
        fill="#999999"
      ></path>
      <path
        id="13145557"
        d="M77.558 281.029L76.8262 280.772L72.3598 286.483L73.0916 286.74L77.558 281.029Z"
        fill="#999999"
      ></path>
      <path
        id="581613e0"
        d="M69.4175 277.885L68.717 277.592L63.6254 283.062L64.3267 283.356L69.4175 277.885Z"
        fill="#999999"
      ></path>
      <path
        id="a473bb0e"
        d="M61.5679 274.38L60.9015 274.053L55.2153 279.253L55.8817 279.581L61.5679 274.38Z"
        fill="#999999"
      ></path>
      <path
        id="a748fe56"
        d="M54.204 270.45L53.576 270.09L47.3294 274.993L47.9582 275.353L54.204 270.45Z"
        fill="#999999"
      ></path>
      <path
        id="66815ad4"
        d="M39.7565 260.763L32.4715 264.972L33.0099 265.392L40.2949 261.183L39.7565 260.763Z"
        fill="#999999"
      ></path>
      <path
        id="32c49190"
        d="M33.8527 255.854L26.1261 259.691L26.6176 260.137L34.3442 256.3L33.8527 255.854Z"
        fill="#999999"
      ></path>
      <path
        id="e5bb85ac"
        d="M28.6054 250.605L20.4756 254.046L20.9158 254.515L29.0457 251.073L28.6054 250.605Z"
        fill="#999999"
      ></path>
      <path
        id="c647aa7f"
        d="M23.9015 245.112L15.4068 248.13L15.7923 248.62L24.2877 245.602L23.9015 245.112Z"
        fill="#999999"
      ></path>
      <path
        id="dc0d1b90"
        d="M19.8917 239.41L11.0826 241.989L11.4119 242.497L20.2217 239.918L19.8917 239.41Z"
        fill="#999999"
      ></path>
      <path
        id="52876d04"
        d="M16.4288 233.543L7.3566 235.672L7.62899 236.195L16.7005 234.066L16.4288 233.543Z"
        fill="#999999"
      ></path>
      <path
        id="ec0ecb67"
        d="M13.6999 227.5L4.4186 229.172L4.63195 229.708L13.9132 228.036L13.6999 227.5Z"
        fill="#999999"
      ></path>
      <path
        id="ce36e6da"
        d="M11.6644 221.308L2.2245 222.512L2.37743 223.057L11.8173 221.853L11.6644 221.308Z"
        fill="#999999"
      ></path>
      <path
        id="f883b6d8"
        d="M10.2555 215.086L0.708927 215.813L0.800672 216.364L10.3479 215.637L10.2555 215.086Z"
        fill="#999999"
      ></path>
      <path
        id="f7565ed8"
        d="M9.62754 208.744L0.0283508 208.998L0.0596486 209.552L9.65955 209.298L9.62754 208.744Z"
        fill="#999999"
      ></path>
      <path
        id="1d0caf0f"
        d="M9.6282 202.422L0.0282974 202.201L-0.00156784 202.755L9.59905 202.976L9.6282 202.422Z"
        fill="#999999"
      ></path>
      <path
        id="3908f262"
        d="M10.3366 196.126L0.787865 195.427L0.696836 195.978L10.2455 196.677L10.3366 196.126Z"
        fill="#999999"
      ></path>
      <path
        id="98f554f2"
        d="M11.7085 189.875L2.26433 188.702L2.11285 189.248L11.557 190.42L11.7085 189.875Z"
        fill="#999999"
      ></path>
      <path
        id="ba8ea8bd"
        d="M13.8542 183.71L4.56723 182.069L4.356 182.606L13.643 184.246L13.8542 183.71Z"
        fill="#999999"
      ></path>
      <path
        id="922681f7"
        d="M16.5725 177.657L7.496 175.555L7.22502 176.079L16.3022 178.181L16.5725 177.657Z"
        fill="#999999"
      ></path>
      <path
        id="8560e166"
        d="M20.0517 171.778L11.2348 169.227L10.907 169.736L19.7231 172.287L20.0517 171.778Z"
        fill="#999999"
      ></path>
      <path
        id="16404bbf"
        d="M24.1539 166.064L15.6479 163.076L15.2638 163.567L23.7692 166.555L24.1539 166.064Z"
        fill="#999999"
      ></path>
      <path
        id="a5b74b3b"
        d="M28.8814 160.605L20.7352 157.196L20.2971 157.668L28.4433 161.076L28.8814 160.605Z"
        fill="#999999"
      ></path>
      <path
        id="e935e2ea"
        d="M34.1664 155.356L26.4234 151.549L25.9341 151.996L33.6771 155.804L34.1664 155.356Z"
        fill="#999999"
      ></path>
      <path
        id="12dfb264"
        d="M40.0829 150.429L32.7838 146.245L32.2468 146.667L39.5453 150.851L40.0829 150.429Z"
        fill="#999999"
      ></path>
    </g>
  );
}
