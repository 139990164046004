export function StructureIcon() {
  return (
    <g xmlns="http://www.w3.org/2000/svg" id="e6c5e7c8">
      <path
        d="M190.974 69.8569L199.606 74.8384V66.7837L190.974 61.7998V69.8569Z"
        fill="white"
      />
      <path
        d="M202.866 98.0137L211.499 103V94.9429L202.866 89.959V98.0137Z"
        fill="white"
      />
      <path
        d="M190.974 91.1475L199.606 96.1314V88.0767L190.974 83.0928V91.1475Z"
        fill="white"
      />
      <path
        d="M179.079 84.2827L187.712 89.2666V81.2095L179.079 76.2256V84.2827Z"
        fill="white"
      />
      <path
        d="M181.573 71.1415L208.811 86.8682V84.5676L181.573 68.8408V71.1415Z"
        fill="white"
      />
      <path
        d="M193.935 81.926L196.74 83.5467V75.8746L193.935 74.2539V81.926Z"
        fill="white"
      />
      <path
        d="M206.111 88.9246L208.916 90.5453V84.6256L206.111 83.0049V88.9246Z"
        fill="white"
      />
      <path
        d="M181.562 74.7772L184.367 76.3979V70.4781L181.562 68.8574V74.7772Z"
        fill="white"
      />
    </g>
  );
}

export function VisionIcon() {
  return (
    <g xmlns="http://www.w3.org/2000/svg" id="8d01179d">
      <path
        d="M138.266 65.3354C138.266 66.7367 137.128 67.8748 135.727 67.8748C134.325 67.8748 133.187 66.7391 133.187 65.3354C133.187 63.9316 134.325 62.7959 135.727 62.7959C137.128 62.7959 138.266 63.9316 138.266 65.3354Z"
        fill="white"
      />
      <path
        d="M134.617 54.9391C120.374 55.0536 111.391 66.045 110.881 66.9321L110.818 67.1222C111.201 67.3123 119.957 77.5288 135.123 77.7188C147.816 77.8773 158.92 66.6129 158.92 66.296C158.856 65.7891 150.351 54.8099 134.614 54.9366L134.617 54.9391ZM140.21 74.2581C134.826 77.0365 128.341 75.7643 126.44 67.1393C125.636 62.6428 127.776 58.9116 130.617 57.0277L128.672 57.5687C141.83 49.7285 153.078 67.5341 140.21 74.2581Z"
        fill="white"
      />
    </g>
  );
}

export function KpiIcon() {
  return (
    <g xmlns="http://www.w3.org/2000/svg" id="bf617d9e">
      <path
        d="M55.61 195.277L52.2224 193.322V188.694L55.61 190.651V195.277Z"
        fill="white"
      />
      <path
        d="M61.8 198.916L58.4782 196.996V189.112L61.8 191.03V198.916Z"
        fill="white"
      />
      <path
        d="M67.5321 202.163L64.3395 200.318V186.439L67.5321 188.281V202.163Z"
        fill="white"
      />
      <path
        d="M73.0723 205.358L69.943 203.555V183.809L73.0723 185.615V205.358Z"
        fill="white"
      />
      <path
        d="M79.0043 208.783L75.7459 206.904V181.423L79.0043 183.305V208.783Z"
        fill="white"
      />
      <path
        d="M79.9151 212.373L47.3357 193.561V191.216L79.9151 210.026V212.373Z"
        fill="white"
      />
      <path
        d="M50.6482 185.757C58.0107 185.53 65.5146 183.863 71.6757 179.667C71.9754 179.464 72.2679 179.252 72.5652 179.048C72.6627 178.982 72.7626 178.918 72.8601 178.85C73.0088 178.748 73.0575 178.772 72.8845 178.823C73.8325 178.55 73.4279 177.069 72.4775 177.344C72.0217 177.476 71.6172 177.846 71.237 178.109C70.4352 178.665 69.6138 179.191 68.7657 179.672C65.8071 181.346 62.573 182.479 59.2561 183.208C56.4266 183.829 53.5411 184.134 50.6482 184.222C49.6636 184.251 49.6587 185.786 50.6482 185.757Z"
        fill="white"
      />
      <path
        d="M67.9833 177.938C69.5138 178.26 71.0541 178.599 72.6187 178.689C73.6057 178.745 73.6008 177.212 72.6187 177.153C71.1905 177.071 69.7867 176.751 68.3903 176.459C67.4276 176.257 67.0157 177.736 67.9833 177.938Z"
        fill="white"
      />
      <path
        d="M71.9793 178.372C72.0402 179.585 72.0427 180.802 72.0427 182.018C72.0427 183.005 73.5781 183.007 73.5781 182.018C73.5781 180.802 73.5781 179.585 73.5147 178.372C73.466 177.39 71.9306 177.382 71.9793 178.372Z"
        fill="white"
      />
    </g>
  );
}

export function StrategyIcon() {
  return (
    <g xmlns="http://www.w3.org/2000/svg" id="b1317915">
      <path
        d="M203.665 182.795C203.926 185.268 202.408 187.791 200.27 188.424C198.135 189.058 196.193 187.569 195.932 185.095C195.671 182.622 197.192 180.099 199.327 179.465C201.462 178.829 203.404 180.321 203.668 182.795H203.665Z"
        fill="white"
      />
      <path
        d="M225.435 188.749C225.696 191.223 224.175 193.745 222.038 194.379C219.903 195.015 217.961 193.523 217.7 191.05C217.439 188.576 218.96 186.053 221.095 185.42C223.23 184.784 225.175 186.273 225.435 188.749Z"
        fill="white"
      />
      <path
        d="M212.606 209.087C214.265 206.055 216.086 203.106 217.919 200.176C218.455 199.319 217.1 198.531 216.563 199.384C214.728 202.314 212.908 205.26 211.251 208.294C210.766 209.182 212.121 209.974 212.606 209.087Z"
        fill="white"
      />
      <path
        d="M218.552 206.303C216.33 204.377 213.958 202.61 211.582 200.88C210.766 200.285 209.981 201.645 210.79 202.235C213.059 203.888 215.321 205.574 217.443 207.414C218.204 208.072 219.318 206.968 218.552 206.305V206.303Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.015 174.248C222.244 174.616 222.132 175.099 221.764 175.328L221.762 175.33L221.753 175.335C221.744 175.341 221.73 175.35 221.712 175.361C221.675 175.385 221.619 175.421 221.545 175.47C221.397 175.568 221.177 175.716 220.895 175.914C220.332 176.311 219.522 176.907 218.545 177.699C216.592 179.283 213.979 181.649 211.345 184.767C206.077 191.004 200.756 200.218 200.433 212.198C200.422 212.631 200.061 212.973 199.628 212.961C199.194 212.95 198.853 212.589 198.864 212.156C199.2 199.711 204.729 190.167 210.146 183.754C212.856 180.546 215.544 178.113 217.556 176.48C218.563 175.663 219.402 175.045 219.992 174.631C220.287 174.423 220.52 174.266 220.68 174.16C220.76 174.107 220.823 174.067 220.865 174.04C220.887 174.026 220.903 174.015 220.915 174.008L220.929 174L220.934 173.996C220.934 173.996 220.935 173.996 221.349 174.662L220.934 173.996C221.302 173.767 221.786 173.88 222.015 174.248Z"
        fill="white"
      />
      <path
        d="M220.217 180.838C220.862 178.849 221.591 176.887 222.334 174.933C222.695 173.99 221.179 173.58 220.821 174.516C220.078 176.468 219.346 178.433 218.703 180.421C218.391 181.384 219.905 181.796 220.217 180.838Z"
        fill="white"
      />
      <path
        d="M221.743 173.985C220.115 173.215 218.43 172.559 216.746 171.923C215.801 171.565 215.394 173.081 216.33 173.436C217.887 174.026 219.444 174.628 220.95 175.34C221.857 175.769 222.654 174.416 221.743 173.985Z"
        fill="white"
      />
    </g>
  );
}

export function StarIcon() {
  return (
    <g xmlns="http://www.w3.org/2000/svg" id="eff88fab">
      <path
        d="M124.751 198.139L108.654 215.336L131.164 201.723C132.754 202.218 134.424 202.515 136.095 202.62L139.442 211.832L140.183 202.493C142.011 202.256 143.763 201.777 145.32 201.056L172.529 213.846L149.824 197.53C150.796 196.19 151.272 194.72 151.263 193.236L164.23 191.413L150.695 190.5C150.292 189.518 149.677 188.562 148.849 187.669L164.97 170.877L142.931 183.881C141.338 183.286 139.646 182.897 137.937 182.709L134.607 173.548L133.887 182.648C131.586 182.83 129.376 183.387 127.469 184.313L100.941 171.821L123.082 187.904C122.202 189.191 121.775 190.587 121.782 191.999L108.531 193.863L122.37 194.796C122.862 195.968 123.659 197.103 124.753 198.136L124.751 198.139ZM127.145 187.054C131.251 183.781 138.796 183.628 143.996 186.714C149.196 189.799 150.084 194.955 145.978 198.228C141.87 201.504 134.325 201.656 129.125 198.571C123.925 195.485 123.037 190.332 127.145 187.056V187.054Z"
        fill="white"
      />
      <path
        d="M177.905 217.623L178.221 218.02L175.454 220.225L175.138 219.829L176.298 218.904L173.673 215.611L174.12 215.255L176.745 218.548L177.905 217.623Z"
        fill="white"
      />
      <path
        d="M138.833 213.79L140.793 216.25L139.963 216.912C139.77 217.066 139.584 217.157 139.406 217.185C139.228 217.214 139.062 217.194 138.909 217.123C138.756 217.053 138.622 216.945 138.507 216.8C138.391 216.655 138.316 216.5 138.281 216.334C138.245 216.169 138.26 216.003 138.326 215.834C138.393 215.667 138.522 215.507 138.713 215.355L139.309 214.88L139.52 215.144L138.934 215.611C138.802 215.717 138.714 215.824 138.67 215.933C138.626 216.043 138.617 216.151 138.643 216.257C138.669 216.365 138.721 216.468 138.8 216.567C138.878 216.665 138.967 216.738 139.065 216.787C139.163 216.836 139.27 216.85 139.386 216.829C139.503 216.81 139.628 216.747 139.762 216.64L140.285 216.223L138.535 214.028L138.833 213.79Z"
        fill="white"
      />
      <path
        d="M168.724 189.959L170.684 192.418L169.21 193.593L168.999 193.329L170.176 192.391L169.514 191.56L168.447 192.41L168.237 192.146L169.303 191.296L168.426 190.196L168.724 189.959Z"
        fill="white"
      />
      <path
        d="M131.81 171.545C131.9 171.605 131.993 171.649 132.087 171.679C132.181 171.71 132.276 171.725 132.372 171.723C132.467 171.722 132.561 171.704 132.655 171.669C132.748 171.633 132.839 171.58 132.928 171.509C133.074 171.393 133.176 171.25 133.236 171.08C133.295 170.909 133.301 170.723 133.255 170.52C133.209 170.318 133.101 170.11 132.931 169.897C132.761 169.684 132.582 169.533 132.394 169.443C132.206 169.353 132.021 169.319 131.84 169.341C131.658 169.363 131.491 169.434 131.339 169.556C131.198 169.668 131.098 169.797 131.039 169.942C130.979 170.089 130.963 170.242 130.991 170.4C131.019 170.56 131.093 170.715 131.213 170.866L131.107 170.919L131.846 170.33L132.057 170.594L131.12 171.34L130.91 171.076C130.748 170.874 130.651 170.663 130.618 170.445C130.585 170.227 130.611 170.016 130.696 169.813C130.781 169.611 130.922 169.433 131.117 169.277C131.335 169.103 131.567 169.002 131.814 168.973C132.059 168.945 132.304 168.988 132.546 169.104C132.788 169.22 133.012 169.408 133.219 169.667C133.374 169.862 133.488 170.057 133.559 170.254C133.631 170.452 133.662 170.645 133.654 170.831C133.645 171.017 133.599 171.192 133.514 171.355C133.429 171.518 133.308 171.662 133.15 171.788C133.021 171.891 132.884 171.968 132.741 172.018C132.597 172.07 132.453 172.095 132.308 172.094C132.163 172.094 132.022 172.069 131.884 172.018C131.747 171.967 131.62 171.891 131.502 171.79L131.81 171.545Z"
        fill="white"
      />
      <path
        d="M101.959 192.581L103.919 195.04L103.621 195.278L102.748 194.182L101.437 195.228L102.31 196.323L102.012 196.56L100.052 194.101L100.35 193.864L101.227 194.964L102.538 193.918L101.661 192.818L101.959 192.581Z"
        fill="white"
      />
      <path
        d="M97.6742 170.237L97.9901 170.633L95.2232 172.839L94.9073 172.443L96.0674 171.518L93.4424 168.225L93.8892 167.869L96.5141 171.162L97.6742 170.237Z"
        fill="white"
      />
      <path
        d="M108.338 219.751L105.887 216.676L106.259 216.379L108.71 219.454L108.338 219.751Z"
        fill="white"
      />
      <path
        d="M166.326 171.321L166.698 171.024C166.806 171.113 166.919 171.177 167.039 171.214C167.158 171.252 167.278 171.267 167.4 171.26C167.522 171.255 167.641 171.229 167.757 171.182C167.873 171.135 167.983 171.07 168.085 170.989C168.271 170.841 168.402 170.659 168.478 170.445C168.553 170.231 168.562 169.997 168.506 169.743C168.448 169.489 168.313 169.23 168.1 168.963C167.888 168.697 167.665 168.508 167.431 168.395C167.196 168.282 166.966 168.239 166.741 168.265C166.514 168.291 166.308 168.379 166.122 168.527C166.02 168.608 165.933 168.7 165.861 168.804C165.79 168.907 165.737 169.016 165.704 169.133C165.671 169.251 165.659 169.372 165.67 169.496C165.68 169.622 165.717 169.747 165.779 169.871L165.407 170.168C165.31 169.988 165.248 169.807 165.224 169.624C165.199 169.441 165.207 169.262 165.247 169.087C165.289 168.913 165.36 168.749 165.461 168.594C165.562 168.441 165.689 168.302 165.844 168.179C166.107 167.97 166.391 167.848 166.697 167.813C167.004 167.778 167.309 167.833 167.615 167.975C167.92 168.118 168.202 168.352 168.461 168.676C168.719 169 168.884 169.327 168.955 169.657C169.026 169.986 169.011 170.297 168.909 170.588C168.807 170.878 168.625 171.128 168.362 171.337C168.207 171.461 168.044 171.555 167.872 171.618C167.699 171.682 167.522 171.715 167.343 171.716C167.164 171.717 166.988 171.686 166.815 171.62C166.643 171.556 166.48 171.456 166.326 171.321Z"
        fill="white"
      />
    </g>
  );
}

export function TeamIcon() {
  return (
    <g xmlns="http://www.w3.org/2000/svg" id="aa303984">
      <path
        d="M68.8516 84.9159C69.3951 84.0629 69.8021 82.2301 68.5884 82.7468C68.5884 82.7468 69.3805 77.4339 65.1838 79.8296C60.9895 82.2253 61.3575 86.9192 61.3575 86.9192C61.3575 86.9192 60.6605 87.3457 60.6459 87.7259C60.5411 90.3701 61.4111 89.0785 61.5427 89.2905C61.8693 89.8242 62.8344 91.8178 64.9206 90.6382C67.0043 89.4635 68.4812 85.2839 68.4812 85.2839C68.4812 85.2839 68.6713 85.1986 68.8516 84.911V84.9159Z"
        fill="white"
      />
      <path
        d="M67.6897 89.7294L66.0544 95.3982L65.8716 92.1007L66.4784 90.5629L63.5758 92.2104L64.129 93.1316L63.8658 96.7142L62.3353 92.8489C62.3353 92.8489 59.5107 94.8522 57.851 97.5477C55.0532 102.093 57.7706 102.398 57.7706 102.398L72.4396 93.9018C72.4396 93.9018 74.1554 90.4849 72.1228 89.493C69.9513 88.4329 67.6897 89.7294 67.6897 89.7294Z"
        fill="white"
      />
      <path
        d="M98.231 70.2977C98.231 70.2977 97.5656 70.0174 97.746 69.335C97.9288 68.6527 100.868 59.5622 93.715 63.6005C86.4913 67.6802 88.8797 73.8876 89.0796 74.1264C89.5036 74.6382 88.6848 75.9299 88.6848 75.9299C88.6848 75.9299 89.684 76.3223 92.0187 75.1866C92.2088 75.094 91.6849 76.5611 91.6849 76.5611C91.6849 76.5611 92.0187 79.3078 93.3811 78.5815C94.9945 77.7212 95.2894 74.6309 95.2894 74.6309L94.8336 73.6219C94.8336 73.6219 97.3487 72.3815 98.2285 70.2977H98.231Z"
        fill="white"
      />
      <path
        d="M96.5332 73.6419C96.5332 73.6419 100.988 71.7678 100.837 74.3999C100.686 77.032 100.686 77.2148 100.352 77.5876C99.9403 78.0507 86.519 85.9202 86.4142 85.7861C83.809 82.4424 90.143 77.361 90.143 77.361L89.5971 80.6438L91.0204 79.0036L92.5655 80.0516L93.4453 80.5122L95.8995 76.0035L97.4154 75.9475L96.5356 73.6371L96.5332 73.6419Z"
        fill="white"
      />
      <path
        d="M83.2631 77.6675C83.887 76.6854 84.355 74.5821 82.9609 75.1768C82.9609 75.1768 83.87 69.0767 79.0518 71.8282C74.2336 74.5797 74.6576 79.9706 74.6576 79.9706C74.6576 79.9706 73.8558 80.4605 73.8388 80.8967C73.7169 83.9358 74.7186 82.4492 74.8697 82.6953C75.245 83.307 76.3539 85.5955 78.7471 84.2429C81.1404 82.8903 82.8366 78.094 82.8366 78.094C82.8366 78.094 83.0535 77.9941 83.2607 77.6675H83.2631Z"
        fill="white"
      />
      <path
        d="M81.9291 83.1922L80.0501 89.7017L79.8381 85.9144L80.5351 84.15L77.2011 86.0436L77.8372 87.0989L77.535 91.2128L75.7778 86.7747C75.7778 86.7747 72.5316 89.0754 70.6282 92.173C67.4161 97.3909 70.5356 97.7394 70.5356 97.7394L87.3834 87.9836C87.3834 87.9836 89.3526 84.0598 87.0203 82.9217C84.5271 81.7055 81.9291 83.1922 81.9291 83.1922Z"
        fill="white"
      />
    </g>
  );
}
