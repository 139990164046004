import "./ResultRow.scss";

export const ResultRow = ({
  text,
  percent,
  color = "#3c485a",
}: {
  text: string;
  percent?: number | null;
  color?: string;
}) => {
  return (
    <div className="result-row" style={{ color }}>
      <div className="text">{text}</div>
      {percent !== undefined && (
        <div className="percent" style={{ backgroundColor: color }}>
          {percent !== null ? percent : "- "}%
        </div>
      )}
    </div>
  );
};
