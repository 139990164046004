import React, { useEffect, useRef } from "react";
import { MultiSelect } from "../MultiSelect";
import { AttributeFilterProps, Attribute } from "./types";
import { useAttributes, useSelectAttributes } from "./hooks";
import { GenericObject } from "../../../../@types";

export function AttributeFilters({
  test,
  onChange,
  selectedValues,
  showLabelInSelect,
  disableFirstAttribute,
}: AttributeFilterProps) {
  const attributes = useAttributes(test);
  const selectAttributes = useSelectAttributes(attributes);
  const selectedAttributesRef = useRef<GenericObject<Attribute>>({});

  useEffect(() => {
    const currentSelectedAttributes = selectedAttributesRef.current;
    const currentSelectedAttributesKeys = Object.keys(
      currentSelectedAttributes
    );

    if (
      selectedValues?.length === 1 &&
      currentSelectedAttributes &&
      currentSelectedAttributesKeys.length === 0
    ) {
      const predefinedValue = selectedValues[0];
      const attribute = Object.values(selectAttributes).find(value => {
        return value.options.find(opt => opt === predefinedValue);
      });

      if (attribute) {
        handleOnChange(attribute.name, attribute.label, [predefinedValue]);
      }
    }
  }, [selectedValues, selectedAttributesRef]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleOnChange(name: string, label: string, options: string[]) {
    const selectedAttributes = selectedAttributesRef.current;
    if (options.length) {
      selectedAttributes[name] = {
        name,
        label,
        options,
      };
    } else {
      delete selectedAttributes[name];
    }

    onChange({ ...selectedAttributes });
  }

  if (!selectAttributes.length) {
    return null;
  }

  return (
    <div className="tg_attribute_filters">
      {selectAttributes.map(({ selectOptions, name, label }, index) => (
        <div
          className="tg_attribute_filter"
          key={name}
          title={showLabelInSelect ? label : undefined}
        >
          {!showLabelInSelect && (
            <div className="tg_attribute_name">{label}</div>
          )}
          <MultiSelect
            placeholder={showLabelInSelect ? label : undefined}
            options={selectOptions}
            isDisabled={index === 0 && disableFirstAttribute}
            onChange={selectedOptions => {
              handleOnChange(
                name,
                label,
                selectedOptions.map(opt => opt.value)
              );
            }}
            selectedValues={selectedValues}
          />
        </div>
      ))}
    </div>
  );
}
