import { UseQueryResult } from "react-query";
import { useGet } from "../../../../../Tool/hooks";
import { API_URLS } from "../../../../../../utils/api-constants";
import { DtoCubeDataResponse } from "../../../@types/dto-types";

export function useRawData(sessionId: string) {
  return useGet(
    `${API_URLS.CUBE_COCKPIT}/${sessionId}/individual-data`
  ) as UseQueryResult<DtoCubeDataResponse>;
}
