import { useEffect, useMemo, useState } from "react";
import { DtoTest } from "../../@types/dto-types";
import { PossibleAttributesMap, SelectedFilters } from "./types";
import { getPossibleAttributesMap } from "./utils";

export function useFilters(
  currentTest: DtoTest | undefined,
  allTests: DtoTest[] | undefined = [],
  selectedTeam: string | null
) {
  const [currentSingleFilters, setCurrentSingleFilters] = useState(
    {} as SelectedFilters
  );

  const possibleAttributesMap = useMemo<PossibleAttributesMap>(
    () => getPossibleAttributesMap(currentTest, allTests),
    [currentTest, allTests]
  );
  const selectedFiltersMultiLanguage = useMemo<SelectedFilters>(() => {
    if (Object.keys(possibleAttributesMap).length === 0) {
      return {};
    }

    return Object.keys(currentSingleFilters).reduce((acc, key: any) => {
      const value = currentSingleFilters[key];
      acc[key] = value.map(val => possibleAttributesMap[key][val]).flat();
      return acc;
    }, {} as SelectedFilters);
  }, [possibleAttributesMap, currentSingleFilters]);

  useEffect(() => {
    const setCurrentSingleFiltersKeys = Object.keys(currentSingleFilters);

    if (selectedTeam && setCurrentSingleFiltersKeys.length === 0) {
      const attributeName = Object.keys(possibleAttributesMap).find(key => {
        const allKeyValues =
          possibleAttributesMap[key as keyof PossibleAttributesMap];
        return !!allKeyValues[selectedTeam];
      }) as keyof SelectedFilters | undefined;

      if (attributeName) {
        setCurrentSingleFilters({ [attributeName]: [selectedTeam] });
      }
    }
  }, [selectedTeam, possibleAttributesMap, currentSingleFilters]);

  return useMemo(() => {
    return {
      selectedFiltersMultiLanguage,
      setCurrentSingleFilters,
      currentSingleFilters,
    };
  }, [
    currentSingleFilters,
    setCurrentSingleFilters,
    selectedFiltersMultiLanguage,
  ]);
}
