import {
  DtoCubeDataResponse,
  DtoEntry,
  DtoTestSetItem,
} from "../../../@types/dto-types";
import "./EvaluationItems.scss";
import { getQuestionChoices } from "../../../../CustomReports/hooks/utils";
import { TEST_TYPES } from "../../../../constants";
import {
  CUBE_SIDE_NUMBER_TO_NAME_MAP,
  CUBE_SIDE_TO_COLOR,
} from "../../../components/Сube/constants";
import { useNavItemsLabels } from "../../../hooks";
import { useTranslation } from "react-i18next";

export interface EvaluationItemsProps {
  rawData: DtoCubeDataResponse | undefined;
}

const COLORS = [
  { color: "#2f7986" },
  { color: "#4db9c5" },
  { color: "#7dc8cd" },
  { color: "#4cb1cf" },
  { color: "#1c6b92" },
  { color: "#3c485a" },
];

export function EvaluationItems({ rawData }: EvaluationItemsProps) {
  const { t } = useTranslation();
  const navItemsLabels = useNavItemsLabels(rawData?.client.name ?? "");
  const filteredQuestions = rawData?.testSets?.[0]?.items?.filter(
    item =>
      item.category === TEST_TYPES.CHOICE && item.type === TEST_TYPES.LIKERT
  ) as DtoTestSetItem[];

  if (filteredQuestions && rawData?.testSets?.[0]) {
    rawData.testSets[0].items = filteredQuestions;
  }

  //@ts-ignore
  const allChoices = rawData ? getQuestionChoices(rawData) : [];
  const combinedChoices = COLORS.map((colorItem, index) => ({
    ...colorItem,
    ...allChoices[index],
  }));

  const stripHtmlWithRegex = (html: string) => html.replace(/<[^>]+>/g, "");

  return (
    <div className="evaluation-wrapper">
      <div className="name">
        {t(
          "cubeCockpit.individualReport.itemsYourEvaluation",
          "Items - YOUR EVALUATION"
        )}
      </div>
      <div className="header">
        {combinedChoices?.map((choice, index) => (
          <div
            key={index}
            className="choice"
            style={{ backgroundColor: choice.color }}
          >
            {choice.name}
          </div>
        ))}
      </div>

      {rawData?.testSets?.map((testSet, testSetIndex) => {
        const cubeSide = CUBE_SIDE_NUMBER_TO_NAME_MAP[+testSet.info.cubeSide];
        const color = CUBE_SIDE_TO_COLOR[cubeSide];
        return (
          <div key={`testSet-${testSetIndex}`}>
            <h2 style={{ color: color }} className="headline">
              {navItemsLabels[cubeSide]}
            </h2>
            {testSet.items
              .filter(
                item =>
                  item.category === TEST_TYPES.CHOICE &&
                  item.type === TEST_TYPES.LIKERT
              )
              .map((question: DtoTestSetItem, questionIndex: number) => {
                const currentValues = +(
                  rawData?.allEntries?.[testSetIndex][
                    question.dbColumn as keyof DtoEntry
                  ] ?? 0
                );

                return (
                  <div key={`question-${testSetIndex}-${questionIndex}`}>
                    <div
                      key={`${testSetIndex}-${questionIndex}`}
                      className="evaluation-question"
                    >
                      <p className="question-text">
                        {stripHtmlWithRegex(question.text)}
                      </p>
                      <div className="evaluation-options">
                        {combinedChoices.map((choice, index) => (
                          <div
                            key={index}
                            className="evaluation-estimation"
                            style={
                              index + 1 === currentValues
                                ? {
                                    backgroundColor: choice.color,
                                    borderColor: choice.color,
                                  }
                                : undefined
                            }
                          >
                            {index + 1 === currentValues && (
                              <span>{currentValues}</span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
}
