import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import * as uuid from "uuid";
import Loader from "../../../components/Loader";
import {
  TEST_LOAD_SUCCESS,
  UPDATE_CURRENT_SESSION_TEST_DATA,
} from "../../../store/actions";
import { Context } from "../../../store/store";
import { API_URLS } from "../../../utils/api-constants";
import { setFavIcon, tryEmbedCssLink } from "../../../utils/embed-utils";
import { axiosGet } from "../../../utils/queries";
import { isObjectId } from "../../../utils/regex";
import {
  ATTRIBUTES_SUB_URL,
  HASH_TYPE,
  PUBLIC_SITE_URL,
  PUBLIC_TEST_URL,
  RESULTS_SUB_URL,
  SPECIAL_ATTRIBUTES_SUB_URL,
} from "../constants";
import {
  TestAttributes,
  TestInfo,
  TestResults,
  TestSet,
  TestSpecialAttributes,
} from "./sub-pages";
import { buildUrlWithGetParams, getTestUrlForSession } from "./utils";
import { useTranslation } from "react-i18next";

function Test() {
  const { i18n } = useTranslation();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const { testSessionData, publicTest } = state;
  const { testId, sessionId = testSessionData.sessionId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (testId && isObjectId(testId)) {
      let url = `${API_URLS.PUBLIC_TEST}/${testId}`;

      if (uuid.validate(sessionId)) {
        url = `${API_URLS.PUBLIC_TEST}/${testId}/${sessionId}`;
      }

      axiosGet(buildUrlWithGetParams(url))
        .then(payload => {
          dispatch({ type: TEST_LOAD_SUCCESS, payload });
          setIsLoading(false);
          history.push(getTestUrlForSession(payload, sessionId));
        })
        .catch(() => {
          history.push("/");
        });
    } else {
      document.location.href = PUBLIC_SITE_URL;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testId, dispatch, history]);

  useEffect(() => {
    const { cssLink: testCssUrl, client, group } = publicTest;
    let cssLink = null;

    if (group?.cssLink) {
      cssLink = tryEmbedCssLink(group.cssLink);
    } else if (testCssUrl) {
      cssLink = tryEmbedCssLink(testCssUrl);
    } else if (client?.cssLink) {
      cssLink = tryEmbedCssLink(client.cssLink);
    }

    return () => {
      if (cssLink) {
        cssLink.remove();
      }
    };
  }, [publicTest]);

  useEffect(() => {
    if (publicTest) {
      i18n.changeLanguage(publicTest.languageId);
    }
  }, [publicTest, i18n]);

  useEffect(() => {
    const { name, client } = publicTest;

    if (name) {
      document.title = name;
    }

    if (client?.favIconUrl) {
      setFavIcon(client.favIconUrl);
    }
  }, [publicTest]);

  useEffect(() => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const isManager = searchParams.get("aKey") === HASH_TYPE.MANAGER;

    dispatch({
      type: UPDATE_CURRENT_SESSION_TEST_DATA,
      payload: { isManager },
    });
  }, [dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route exact path={`${PUBLIC_TEST_URL}/:testId`} component={TestInfo} />
      <Route
        exact
        path={`${PUBLIC_TEST_URL}/:testId/${SPECIAL_ATTRIBUTES_SUB_URL}`}
        component={TestSpecialAttributes}
      />
      <Route
        exact
        path={`${PUBLIC_TEST_URL}/:testId/${ATTRIBUTES_SUB_URL}`}
        component={TestAttributes}
      />
      <Route
        exact
        path={`${PUBLIC_TEST_URL}/:testId/${RESULTS_SUB_URL}/:sessionId`}
        component={TestResults}
      />
      <Route
        exact
        path={`${PUBLIC_TEST_URL}/:testId/:testSetId`}
        component={TestSet}
      />
    </Switch>
  );
}

export default Test;
