import { CubeSideName } from "../../components/Сube/types";
import { DtoEntry, DtoTestSet, DtoTestSetItem } from "../../@types/dto-types";
import { getQuestionsData } from "../../../CustomReports/hooks/utils";
import { Category, ReportSection } from "./types";
import { EntityResponse } from "../../../CustomReports/types";
import { CUBE_SIDES_INTERSECTIONS } from "../../components/Сube/constants";
import { normalizeValueToPercentage } from "./utils";
import { getStatisticData } from "../../../Cockpit/hooks/useStatisticData";

function filterItemsByCategory(items: DtoTestSetItem[], category: Category) {
  return items.filter(item => item.category === category);
}

function filterItemsByIntersection(
  items: DtoTestSetItem[],
  intersection: number
) {
  return items.filter(item => item.intersection === intersection);
}

function getQuestionsDataForCategory(
  testSet: DtoTestSet,
  entries: DtoEntry[],
  filterPredicate: (items: DtoTestSetItem[]) => DtoTestSetItem[]
) {
  const data = getQuestionsData(
    {
      testSets: [{ ...testSet, items: filterPredicate(testSet.items) }],
      entries,
    } as unknown as EntityResponse,
    false
  ) as ReportSection;

  data.questions = data.questions.map(question => ({
    ...question,
    average: normalizeValueToPercentage(question.average ?? 0),
  }));

  return data;
}

function mapEntriesToSingleTestSetId(_id: string, entries: DtoEntry[]) {
  return entries.map(entry => ({
    ...entry,
    testSetId: _id,
  }));
}

export function getAggregatedItemsReport(
  currentTestId: string,
  sideName: CubeSideName,
  testSetsByCubeSide: Record<CubeSideName, DtoTestSet[]>,
  entriesGroupedBySide: Record<CubeSideName, DtoEntry[]>
) {
  const testSet = testSetsByCubeSide[sideName].find(
    ts => ts.testId === currentTestId
  );

  if (!testSet) {
    return null;
  }

  const { _id } = testSet;
  // make all entries as one testSet
  const sideEntries = mapEntriesToSingleTestSetId(
    _id,
    entriesGroupedBySide[sideName]
  );

  const testSetWithOtherItems = {
    ...testSet,
    items: testSet.items.filter(item => !item.category),
  };

  return {
    averageScore: getQuestionsDataForCategory(testSet, sideEntries, items =>
      filterItemsByCategory(items, Category.Consent)
    ),
    implementationQuality: getQuestionsDataForCategory(
      testSet,
      sideEntries,
      items => filterItemsByCategory(items, Category.Quality)
    ),
    importance: getQuestionsDataForCategory(testSet, sideEntries, items =>
      filterItemsByCategory(items, Category.Importance)
    ),
    intersections: CUBE_SIDES_INTERSECTIONS[sideName].reduce(
      (acc, { side, intersection }) => {
        const otherSideTestSet = testSetsByCubeSide[side].find(
          ts => ts.testId === currentTestId
        )!;

        if (!otherSideTestSet) {
          return acc;
        }

        const otherSideEntries = mapEntriesToSingleTestSetId(
          otherSideTestSet._id,
          entriesGroupedBySide[side]
        );
        const currentSideQuestions = getQuestionsDataForCategory(
          testSet,
          sideEntries,
          items =>
            filterItemsByIntersection(
              filterItemsByCategory(items, Category.Intersection),
              intersection
            )
        );
        const otherSideQuestions = getQuestionsDataForCategory(
          otherSideTestSet,
          otherSideEntries,
          items =>
            filterItemsByIntersection(
              filterItemsByCategory(items, Category.Intersection),
              intersection
            )
        );

        acc[side] = {
          choices: currentSideQuestions.choices,
          questions: [
            ...currentSideQuestions.questions,
            ...otherSideQuestions.questions,
          ],
        };

        return acc;
      },
      {} as Record<CubeSideName, any>
    ),
    otherScore: getStatisticData([testSetWithOtherItems] as any, sideEntries),
  };
}
