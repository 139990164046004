import { useEffect, useMemo, useState } from "react";
import Select, { OnChangeValue } from "react-select";
import { SelectableOption } from "./components/SelectableOption";
import { MultiValue } from "./components/MultiValue";
import { MultiSelectProps, MultiValueOption } from "./types";
import { ActionMeta } from "react-select/dist/declarations/src/types";
import { getNewSelectedOptions, getOptionsWithoutHiddenItems } from "./utils";
import { TOGGLE_ALL_VALUE } from "./constants";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const EMPTY_ARRAY: never[] = [];

export function MultiSelect({
  options = EMPTY_ARRAY,
  onChange,
  selectedValues = EMPTY_ARRAY,
  placeholder,
  isDisabled,
}: MultiSelectProps) {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<MultiValueOption[]>(
    []
  );

  const allOptions = useMemo(() => {
    return [
      {
        value: TOGGLE_ALL_VALUE,
        label: t("frontend.reports.multiSelect.selectAll", "Select All"),
        isHiddenInSelection: true,
      },
      ...options,
    ];
  }, [options, t]);

  useEffect(() => {
    const selectedAndFilteredOptions = options!.filter(opt =>
      selectedValues.includes(opt.value)
    );
    setSelectedOptions(
      selectedAndFilteredOptions.length === options.length
        ? allOptions
        : selectedAndFilteredOptions
    );
  }, [selectedValues, options, setSelectedOptions, allOptions]);

  function handleOnChange(
    selectedOptions: OnChangeValue<MultiValueOption, true>,
    actionMeta: ActionMeta<MultiValueOption>
  ) {
    const mvSelectedOptions = selectedOptions as MultiValueOption[];
    const filteredOptions = getOptionsWithoutHiddenItems(mvSelectedOptions);
    const newSelectedOptions = getNewSelectedOptions(
      options,
      allOptions,
      filteredOptions,
      mvSelectedOptions,
      actionMeta
    );

    setSelectedOptions(newSelectedOptions);
    onChange?.(getOptionsWithoutHiddenItems(newSelectedOptions));
  }

  return (
    <Select
      classNamePrefix="multi-select"
      value={selectedOptions}
      isMulti
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      hideSelectedOptions={false}
      onChange={handleOnChange}
      options={allOptions}
      isDisabled={isDisabled}
      placeholder={
        placeholder ||
        t("frontend.reports.multiSelect.placeHolder", "Select...")
      }
      components={{
        Option: SelectableOption,
        MultiValue,
      }}
      noOptionsMessage={() =>
        t("frontend.reports.multiSelect.noOptions", "No options")
      }
    />
  );
}
