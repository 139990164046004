import { CubeSideName, CubeSidesIntersectionsMap } from "./types";

export const CUBE_SIDES_INTERSECTIONS: CubeSidesIntersectionsMap = {
  leader: [
    { side: "team", intersection: 3 },
    { side: "structures", intersection: 4 },
    { side: "strategy", intersection: 1 },
    { side: "kpis", intersection: 2 },
  ],
  vision: [
    { side: "structures", intersection: 8 },
    { side: "strategy", intersection: 5 },
    { side: "kpis", intersection: 6 },
    { side: "team", intersection: 7 },
  ],
  strategy: [
    { side: "vision", intersection: 5 },
    { side: "structures", intersection: 12 },
    { side: "leader", intersection: 1 },
    { side: "kpis", intersection: 9 },
  ],
  kpis: [
    { side: "vision", intersection: 6 },
    { side: "strategy", intersection: 9 },
    { side: "leader", intersection: 2 },
    { side: "team", intersection: 10 },
  ],
  team: [
    { side: "vision", intersection: 7 },
    { side: "kpis", intersection: 10 },
    { side: "leader", intersection: 3 },
    { side: "structures", intersection: 11 },
  ],
  structures: [
    { side: "vision", intersection: 8 },
    { side: "team", intersection: 11 },
    { side: "leader", intersection: 4 },
    { side: "strategy", intersection: 12 },
  ],
};

export const CUBE_SIDE_NAMES: CubeSideName[] = [
  "team",
  "leader",
  "structures",
  "strategy",
  "kpis",
  "vision",
];

export const CUBE_SIDE_NAME_TO_NUMBER_MAP: Record<CubeSideName, number> = {
  leader: 1,
  vision: 2,
  strategy: 3,
  kpis: 4,
  team: 5,
  structures: 6,
};

export const CUBE_SIDE_NUMBER_TO_NAME_MAP: Record<number, CubeSideName> = {
  1: "leader",
  2: "vision",
  3: "strategy",
  4: "kpis",
  5: "team",
  6: "structures",
};

export const CUBE_SIDE_TO_COLOR: Record<CubeSideName, string> = {
  team: "#BE7DBC",
  strategy: "#A1A1A3",
  vision: "#73C9EC",
  leader: "#7D7AA1",
  kpis: "#BCDE90",
  structures: "#71CBC5",
};

export const CUBE_SIDE_GREY_COLOR = "#C1C1C1CC";
