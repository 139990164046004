import * as React from "react";

const PersonIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={15}
    viewBox="0 0 14 15"
    fill="none"
    {...props}
  >
    <g id="Group 128">
      <path
        id="Vector"
        d="M12.7179 13.7995L12.7179 12.451C12.7179 11.7357 12.4337 11.0496 11.9279 10.5439C11.4221 10.0381 10.7361 9.75391 10.0208 9.75391L4.62674 9.75391C3.91143 9.75391 3.22543 10.0381 2.71963 10.5439C2.21384 11.0496 1.92969 11.7357 1.92969 12.451L1.92969 13.7995"
        stroke="#3C485A"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M7.32205 7.05816C8.81159 7.05816 10.0191 5.85065 10.0191 4.36111C10.0191 2.87157 8.81159 1.66406 7.32205 1.66406C5.83251 1.66406 4.625 2.87157 4.625 4.36111C4.625 5.85065 5.83251 7.05816 7.32205 7.05816Z"
        stroke="#3C485A"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default PersonIcon;
