import { DtoTest } from "../../@types/dto-types";
import { PossibleAttributesMap } from "./types";
import { Attribute } from "../../../components/AttributeFilters/types";
import { parseAttributes } from "../../../components/AttributeFilters/utils";

export function parseAllAttributes(test: DtoTest): Attribute[] {
  const regular = parseAttributes(test, "attribute");
  const special = parseAttributes(test, "specialAttribute");
  return [...regular, ...special];
}

export function getPossibleAttributesMap(
  currentTest: DtoTest | undefined,
  allTests: DtoTest[]
) {
  const result = {} as PossibleAttributesMap;

  if (!currentTest) {
    return result;
  }

  const attributesForCurrentTest = parseAllAttributes(currentTest);

  const otherTests = allTests.filter(test => test._id !== currentTest._id);
  const otherTestsAttributes = otherTests.flatMap(parseAllAttributes);

  attributesForCurrentTest.forEach(attr => {
    result[attr.name as any] = {};
    const matchingNameAttributes = otherTestsAttributes.filter(
      a => a.name === attr.name
    );

    attr.options.forEach((option, index) => {
      const optionValues = [option];
      matchingNameAttributes.forEach(otherAttr => {
        optionValues.push(otherAttr.options[index]);
      });

      result[attr.name as any][option] = optionValues;
    });
  });

  return result;
}
