import { useRawData } from "./hooks/useRawData";
import { useParams } from "react-router";
import { useHydratedCubeCockpitData } from "../../hooks";
import {
  CalculationType,
  useCubeOverviewData,
} from "../../hooks/useCubeOverviewData";
import { ExtendedCube } from "../../components/ExtendedCube";
import logoSvg from "./../../assets/logo.svg";
import cornerSvg from "./../../assets/corner.svg";
import cubeSvg from "./../../assets/cube.svg";
import "./IndividualReport.scss";
import { useCurrentTest } from "../../hooks/useCurrentTest";
import { EvaluationItems } from "./components/EvaluationItems";
import { getCurrentFormattedDate } from "./utils";

export function IndividualReport() {
  const { sessionId } = useParams<{ sessionId: string }>();
  const { data: rawData } = useRawData(sessionId);
  const currentTest = useCurrentTest(undefined, rawData);
  const valuesPerSide = useHydratedCubeCockpitData(
    rawData,
    undefined,
    currentTest?._id ?? ""
  );

  const cubeOverviewData = useCubeOverviewData(valuesPerSide);
  console.log(currentTest, cubeOverviewData);
  return (
    <div className="individual-report-page">
      <div className="page1">
        <div className="blue-bar"></div>
        <div className="content">
          <div className="title">
            <span className="small-text">The</span>
            <span className="main-text">Leadership</span>
            <span className="side-text">Cube</span>
          </div>
          <div className="subtitle">YOUR CUBE</div>
        </div>
        <div className="cube-container">
          <img src={cubeSvg} alt="Cube" />
        </div>
        <img src={cornerSvg} alt="Corner" />
      </div>
      <div className="page-break"></div>
      <div className="page2">
        <div className="header">
          <img src={logoSvg} alt="Logo" />
          <div className="date">{getCurrentFormattedDate()}</div>
        </div>
        <div className="content">
          {currentTest?.startReportHtml && (
            <div
              className="start-report-text"
              dangerouslySetInnerHTML={{ __html: currentTest.startReportHtml }}
            />
          )}
        </div>
        <ExtendedCube
          scaleFactor={1}
          cubeOverviewData={cubeOverviewData}
          scoreCalculationType={CalculationType.AverageScore}
          showBenchmark={true}
        />
        <div className="page-break"></div>
      </div>
      <EvaluationItems rawData={rawData} />
    </div>
  );
}
// http://localhost:3000/cube-cockpit-individual-report/65e0eceb-308f-4509-a1ea-5d8340cf19b3
