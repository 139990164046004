export const PUBLIC_START_URL = "/start";
export const PUBLIC_TEST_URL = "/test";
export const PUBLIC_REPORT_URL = "/report";
export const PUBLIC_GROUP_URL = "/group";
export const CUSTOM_PUBLIC_REPORT_URL = "/creport";
export const CUSTOM_PUBLIC_GROUP_URL = "/cgroup";
export const PUBLIC_COCKPIT_URL = "/cockpit";
export const CUBE_COCKPIT_URL = "/cube-cockpit";
export const CUBE_COCKPIT_INDIVIDUAL_REPORT_URL =
  "/cube-cockpit-individual-report";
export const ATTRIBUTES_SUB_URL = "attributes";
export const SPECIAL_ATTRIBUTES_SUB_URL = "special-attributes";
export const RESULTS_SUB_URL = "results";
export const PUBLIC_SITE_URL = "https://sl.management-analytics.de";

export const TEST_TYPES = {
  LIKERT: "L",
  TEXT: "T",
  CHOICE: "C",
  NUMERICAL: "N",
  MULTI_CHOICE: "M",
  SUB_HEADING: "S",
};

export const VISUALIZATIONS_TYPES = {
  TRAFFIC_LIGHT: "A",
  GAUGE: "G",
  CATEGORY: "C",
  TEXT: "T",
  NO_VISUALIZATION: "N",
};

export const MAX_ATTRIBUTES_COUNT = 5;
export const MAX_LIKERT_QUESTIONS = 50;

export const HASH_TYPE = {
  MANAGER: "1d0258c244",
  NOT_MANAGER: "5eccef52f9",
};
