import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { UPDATE_CURRENT_SESSION_TEST_DATA } from "../../../../../store/actions";
import { Context } from "../../../../../store/store";
import { PUBLIC_TEST_URL } from "../../../constants";
import { buildUrlWithGetParams, canStartTest } from "../../utils";
import Button from "./../../components/button";
import "./styles.scss";

function Test() {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const {
    publicTest: { _id, startHtml, startManagerHtml, sets, entries },
    testSessionData: { isManager, sessionId },
  } = state;

  const html = isManager ? startManagerHtml : startHtml;

  const onStartButtonClick = () => {
    if (
      !(
        (entries && entries.length && entries[0].sessionId === sessionId) // check if we have entries with same session ID, then continue
      )
    ) {
      const newSessionId = uuidv4();
      dispatch({
        type: UPDATE_CURRENT_SESSION_TEST_DATA,
        payload: { sessionId: newSessionId },
      });
    }

    history.push(
      buildUrlWithGetParams(`${PUBLIC_TEST_URL}/${_id}/${sets[0]._id}`)
    );
  };

  return (
    <div className="tg_page tg_test-info">
      <div
        className="tg_test-welcome-html"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
      <div className="tg_column">
        <Button
          large
          onClick={onStartButtonClick}
          disabled={!canStartTest(state.publicTest)}
        >
          {t("frontend.test.startTestButtonText", "Start")}
        </Button>
      </div>
    </div>
  );
}

export default Test;
