export function calculateTranslateForScaledPolylineFromString(
  pointsString: string,
  scaleFactor: number
) {
  // Helper function to get bounding box from points
  function getBoundingBox(points: number[]) {
    let minX = Infinity,
      minY = Infinity,
      maxX = -Infinity,
      maxY = -Infinity;
    for (let i = 0; i < points.length; i += 2) {
      const x = points[i];
      const y = points[i + 1];
      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
    }
    return { minX, minY, maxX, maxY };
  }

  // Convert points string to an array of numbers
  const originalPoints = pointsString
    .split(" ")
    .reduce<number[]>((arr, point) => {
      const [x, y] = point.split(",").map(Number);
      arr.push(x, y);
      return arr;
    }, []);

  // Scale the original points
  const scaledPoints: number[] = [];
  for (let i = 0; i < originalPoints.length; i++) {
    scaledPoints.push(originalPoints[i] * scaleFactor);
  }

  // Get bounding boxes for original and scaled points
  const originalBBox = getBoundingBox(originalPoints);
  const scaledBBox = getBoundingBox(scaledPoints);

  // Calculate center of bounding boxes
  const originalCenterX = (originalBBox.minX + originalBBox.maxX) / 2;
  const originalCenterY = (originalBBox.minY + originalBBox.maxY) / 2;
  const scaledCenterX = (scaledBBox.minX + scaledBBox.maxX) / 2;
  const scaledCenterY = (scaledBBox.minY + scaledBBox.maxY) / 2;

  // Calculate translation needed
  const translateX = originalCenterX - scaledCenterX;
  const translateY = originalCenterY - scaledCenterY;

  // Convert scaled points back to string format
  const scaledPointsString = scaledPoints
    .reduce((str, point, index) => {
      if (index % 2 === 0) {
        str += `${point},${scaledPoints[index + 1]} `;
      }
      return str;
    }, "")
    .trim();

  // Return the translation and scaled points string
  return { translateX, translateY, scaledPointsString };
}
