import { useMemo } from "react";
import { ValuesPerSide } from "../useHydratedCubeCockpitData/types";
import { getAverageOfSides } from "./utils";
import { CubeSideName } from "../../components/Сube/types";
import { OverviewData } from "./types";

export function useCubeOverviewData(
  valuesPerSide?: Record<CubeSideName, ValuesPerSide>
) {
  return useMemo<OverviewData>(
    () => getAverageOfSides(valuesPerSide),
    [valuesPerSide]
  );
}
