import { useGet } from "../../../Tool/hooks";
import { API_URLS } from "../../../../utils/api-constants";
import { UseQueryResult } from "react-query";
import { DtoCubeDataResponse } from "../@types/dto-types";

export function useRawCubeCockpitData(clientId: string) {
  return useGet(
    `${API_URLS.CUBE_COCKPIT}/${clientId}/data`
  ) as UseQueryResult<DtoCubeDataResponse>;
}
