import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Cube } from "../Сube";
import {
  CUBE_SIDE_GREY_COLOR,
  CUBE_SIDE_NAMES,
  CUBE_SIDE_TO_COLOR,
} from "../Сube/constants";
import { CubeSideName } from "../Сube/types";
import { SideScore } from "./components/Score";
import { CalculationType, OverviewData } from "../../hooks/useCubeOverviewData";

import "./ExtendedCube.scss";

export interface ExtendedCubeProps {
  scaleFactor: number;
  cubeOverviewData: OverviewData;
  scoreCalculationType: CalculationType;
  onCubeSideClick?: (side?: CubeSideName) => void;
  showBenchmark: boolean;
}

export function ExtendedCube({
  scaleFactor,
  cubeOverviewData,
  scoreCalculationType,
  onCubeSideClick,
  showBenchmark,
}: ExtendedCubeProps) {
  const { t } = useTranslation();
  const [hoveredSide, setHoveredSide] = useState<CubeSideName>();

  const renderSideScore = (side: CubeSideName, titleKey: string) => (
    <SideScore
      percent={cubeOverviewData[scoreCalculationType]["percentageValue"][side]}
      benchmark={
        showBenchmark
          ? cubeOverviewData["benchmarkPerSidePercentageScore"][side]
          : undefined
      }
      title={t(titleKey)}
      color={
        hoveredSide
          ? hoveredSide === side
            ? CUBE_SIDE_TO_COLOR[side]
            : CUBE_SIDE_GREY_COLOR
          : CUBE_SIDE_TO_COLOR[side]
      }
    />
  );

  return (
    <div className="extended-cube-data">
      <div className="row justify-center">
        {renderSideScore("vision", "cubeCockpit.navItems.vision")}
      </div>
      <div className="row justify-center">
        <div
          className="side-group left"
          style={{ gap: `calc(127px * ${scaleFactor})` }}
        >
          {renderSideScore("team", "cubeCockpit.navItems.team")}
          {renderSideScore("kpis", "cubeCockpit.navItems.kpis")}
        </div>
        <div className="row justify-center">
          <Cube
            greyOutOtherSidesOnHover
            scale={scaleFactor}
            values={cubeOverviewData[scoreCalculationType]["cubeSideFillArea"]}
            selectedSides={CUBE_SIDE_NAMES}
            onSideHover={setHoveredSide}
            onSideClick={onCubeSideClick}
          />
        </div>
        <div
          className="side-group right"
          style={{ gap: `calc(127px * ${scaleFactor})` }}
        >
          {renderSideScore("structures", "cubeCockpit.navItems.structures")}
          {renderSideScore("strategy", "cubeCockpit.navItems.strategy")}
        </div>
      </div>
      <div className="row justify-center">
        {renderSideScore("leader", "cubeCockpit.navItems.leader")}
      </div>
    </div>
  );
}
