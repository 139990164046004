import { useTranslation } from "react-i18next";
import { ReportSection } from "../../hooks/useHydratedCubeCockpitData/types";
import CloseIcon from "../../icons/CloseIcon";
import "./Report.scss";
import "./SideReport.scss";
import { useContainerHeight } from "./useContainerHeight";

const colors = [
  "#2F7986",
  "#4DB9C5",
  "#7DC8CD",
  "#4CB1CF",
  "#1C6B92",
  "#3C485A",
];

const ReportPercentage = ({
  color,
  width,
  text,
}: {
  color: string;
  width?: number;
  text: number | string;
}) => {
  return (
    <div
      className="report-section"
      style={{
        backgroundColor: color,
        width: width !== undefined ? `max(30px, ${width}%)` : "100%",
        flex: width === 0 ? 1 : undefined,
      }}
    >
      {text}
    </div>
  );
};

export const SideReport = ({
  color,
  report,
  title,
  onCloseClick,
}: {
  color: string;
  report: ReportSection;
  title: string;
  onCloseClick: () => void;
}) => {
  const { t } = useTranslation();

  const { wrapperRef, topBarRef, contentRef } = useContainerHeight();

  return (
    <div className="cb-report cb-panel side-report" ref={wrapperRef}>
      <div className="cb-report-top-bar" ref={topBarRef}>
        <div className="cb-top-bar">
          <div className="cb-top-title" style={{ color }}>
            {title}
          </div>
          <div className="cb-top-close">
            <CloseIcon onClick={onCloseClick} />
          </div>
        </div>
        <div className="cb-report-container">
          <div className="report-item">
            <div className="report-row">
              <div className="report-body">
                <div className="report-row">
                  {report.choices.map((c, i) => (
                    <ReportPercentage
                      key={c.key}
                      color={colors[i]}
                      text={c.name}
                    />
                  ))}
                </div>
                <div className="report-summary" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="cb-report-container"
        ref={contentRef}
        style={{ maxHeight: 0, scrollbarColor: `${color} #f1f1f1` }}
      >
        {report.questions.map((q, i) => (
          <div className="report-item" key={i}>
            <div className="report-title">{q.text}</div>
            <div className="report-body">
              <div className="report-row">
                {Object.entries(q.entries).map(([k, v], i) => {
                  const value = Math.round(v);
                  return (
                    <ReportPercentage
                      key={k}
                      color={colors[i]}
                      width={value}
                      text={`${value}%`}
                    />
                  );
                })}
              </div>
              <div className="report-summary">
                {t("cubeCockpit.report.average", "Average")}:{" "}
                <b>{Math.round(q.average || 0)}%</b>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
