export function getCurrentFormattedDate() {
  const now = new Date();
  const formattedDate = now.toLocaleDateString("en-GB").replace(/\//g, ".");

  const formattedTime = now.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${formattedDate} - ${formattedTime}`;
}
