import { REPLACE_TEST_ENTRIES } from "../../../store/actions";
import { API_URLS } from "../../../utils/api-constants";
import { axiosPost } from "../../../utils/queries";
import {
  ATTRIBUTES_SUB_URL,
  MAX_ATTRIBUTES_COUNT,
  PUBLIC_TEST_URL,
  RESULTS_SUB_URL,
  SPECIAL_ATTRIBUTES_SUB_URL,
} from "../constants";

export const buildUrlWithGetParams = url => {
  const queryString = window.location.search;
  return `${url}${queryString}`;
};

export const getTestUrlForSession = (testData, sessionId) => {
  const { _id, entries, sets } = testData;

  if (sets.length === 0) {
    // Not valid case, show home page
    return "/";
  }

  if (!entries.length) {
    return buildUrlWithGetParams(`${PUBLIC_TEST_URL}/${_id}`);
  }

  const uncompletedTestSets = getUncompletedTestSets(testData);

  if (testHasUncompletedTestSets(testData)) {
    return buildUrlWithGetParams(
      `${PUBLIC_TEST_URL}/${_id}/${uncompletedTestSets[0]._id}`
    );
  } else if (
    testHasSpecialAttributes(testData) &&
    !hasAllSpecialAttributesFilledIn(testData)
  ) {
    return buildUrlWithGetParams(
      `${PUBLIC_TEST_URL}/${_id}/${SPECIAL_ATTRIBUTES_SUB_URL}`
    );
  } else if (
    testHasRegularAttributes(testData) &&
    !hasAllRegularAttributesFilledIn(testData)
  ) {
    return buildUrlWithGetParams(
      `${PUBLIC_TEST_URL}/${_id}/${ATTRIBUTES_SUB_URL}`
    );
  } else {
    return buildUrlWithGetParams(
      `${PUBLIC_TEST_URL}/${_id}/${RESULTS_SUB_URL}/${sessionId}`
    );
  }
};

export const getUncompletedTestSets = testData => {
  const { entries, sets } = testData;
  const completedEntries = entries.filter(entry => entry.completed);
  return sets.filter(
    set => !completedEntries.find(entry => entry.testSetId === set._id)
  );
};

export const testHasUncompletedTestSets = testData => {
  return !!getUncompletedTestSets(testData).length;
};

export const isLastTestSet = testData => {
  const uncompletedTestSetsCount = getUncompletedTestSets(testData).length;
  return uncompletedTestSetsCount <= 1;
};

export const testHasAttributes = (testData, specialAttributes) => {
  return attributesMapper.some(
    index =>
      testData[
        specialAttributes
          ? `specialAttribute${index}Label`
          : `attribute${index}Label`
      ]
  );
};

export const testHasRegularAttributes = testData => {
  return testHasAttributes(testData, false);
};

export const testHasSpecialAttributes = testData => {
  return testHasAttributes(testData, true);
};

export const getUnAnsweredQuestionIndex = (currentSet, entries, isManager) => {
  const allItemsOptional = currentSet.items.every(item => !item.mandatory);

  if (allItemsOptional) {
    return -1;
  }

  const items = isManager
    ? currentSet.items
    : currentSet.items.filter(item => item.manager === false);

  return items.findIndex(
    item => !entries || (item.mandatory && !entries[item.dbColumn])
  );
};

export const smoothScrollTo = (top = 0) => {
  window.scrollTo({ top, behavior: "smooth" });
};

export const smoothScrollIntoView = element => {
  element.scrollIntoView({ block: "start", behavior: "smooth" });
};

export const attributesMapper = [...Array(MAX_ATTRIBUTES_COUNT).keys()].map(
  i => i + 1
); // Currently only 3 attributes supported

export const getAvailableAttributes = (testData, specialAttributes) => {
  return attributesMapper
    .map(i => {
      const labelKey = specialAttributes
        ? `specialAttribute${i}Label`
        : `attribute${i}Label`;
      const valueKey = specialAttributes
        ? `specialAttribute${i}Value`
        : `attribute${i}Value`;
      const label = testData[labelKey];
      const values = testData[valueKey];

      if (label && values) {
        return specialAttributes ? `specialAttribute${i}` : `attribute${i}`;
      }

      return false;
    })
    .filter(item => item);
};

export const hasAllAttributesFilledIn = (testData, specialAttributes) => {
  const availableAttributes = getAvailableAttributes(
    testData,
    specialAttributes
  );
  return testData.entries.every(testEntry =>
    availableAttributes.every(attr => testEntry[attr])
  );
};

export const hasAllRegularAttributesFilledIn = testData => {
  return hasAllAttributesFilledIn(testData, false);
};

export const hasAllSpecialAttributesFilledIn = testData => {
  return hasAllAttributesFilledIn(testData, true);
};

export const canStartTest = testData => {
  const {
    completedTestFlowsCount = 0,
    isActive = true,
    maxReplies = 0,
    group: { participantsLimit } = { participantsLimit: 0 },
  } = testData;

  const testMaxRepliesThreshold =
    maxReplies === 0 ||
    (maxReplies > 0 && maxReplies > completedTestFlowsCount);

  const groupMaxRepliesThreshold =
    participantsLimit === 0 ||
    (participantsLimit > 0 && participantsLimit > completedTestFlowsCount);

  return isActive && testMaxRepliesThreshold && groupMaxRepliesThreshold;
};

export const getGroupIdFromUrl = () => {
  return new URLSearchParams(window.location.search).get("groupId");
};

export const saveAttributes = ({
  publicTest,
  sessionId,
  currentAttributes,
  state,
  dispatch,
  history,
}) => {
  const data = {
    testId: publicTest._id,
    sessionId: sessionId,
    ...currentAttributes,
  };

  axiosPost(API_URLS.PUBLIC_TEST_ATTRIBUTES, { data }).then(payload => {
    dispatch({ type: REPLACE_TEST_ENTRIES, payload });
    history.push(
      getTestUrlForSession({ ...state.publicTest, entries: payload }, sessionId)
    );
  });
};
