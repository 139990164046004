import React from "react";
import {
  StructureIcon,
  StarIcon,
  KpiIcon,
  TeamIcon,
  VisionIcon,
  StrategyIcon,
} from "./sides-icons";
import { calculateTranslateForScaledPolylineFromString } from "./utils";
import { CubeSideName } from "../../types";
import { SideContainer } from "./styled";
import { CUBE_SIDE_GREY_COLOR, CUBE_SIDE_TO_COLOR } from "../../constants";

export const CUBE_SIDE_POINTS: Record<CubeSideName, string> = {
  team: "35.7227,152.524 35.7227,57.0903 115.152,4.15225 115.152,99.5868 35.7227,152.524",
  strategy:
    "247.364,114.382 165.934,168.652 165.934,266.489 247.364,212.219 247.364,114.382",
  vision:
    "136.117,12.8417 56.0707,66.1907 135.934,119.814 215.98,66.4654 136.117,12.8417",
  leader:
    "217.168,192.901 135.738,138.631 54.4959,193.181 135.925,247.451 217.168,192.901",
  kpis: "106.572,169.578 25.1504,115.297 25.1504,213.134 106.572,267.416 106.572,169.578",
  structures:
    "237.028,57.0575 155.9,2.33646 155.358,100.172 236.485,154.893 237.028,57.0575",
};

export const CUBE_SIDE_TO_ICON: Record<CubeSideName, React.FC> = {
  team: TeamIcon,
  strategy: StrategyIcon,
  vision: VisionIcon,
  leader: StarIcon,
  kpis: KpiIcon,
  structures: StructureIcon,
};

export interface CubeSideProps {
  side: CubeSideName;
  onMouseEnter: (side: CubeSideName) => void;
  onMouseLeave: (side: CubeSideName) => void;
  onClick: (side: CubeSideName) => void;
  grayOut: boolean;
  scale: number;
}

export interface ScalablePolylineProps {
  scale: number;
  points: string;
  color: string;
}

export function ScalablePolyline({
  scale,
  points,
  color,
}: ScalablePolylineProps) {
  const { translateX, translateY, scaledPointsString } =
    calculateTranslateForScaledPolylineFromString(points, scale);

  return (
    <polyline
      points={scaledPointsString}
      stroke="#FFFFFF"
      fill={color}
      opacity={0.9}
      strokeWidth="2"
      transform={`translate(${translateX}, ${translateY})`}
    />
  );
}

export function CubeSide({
  side,
  onMouseEnter,
  onMouseLeave,
  onClick,
  grayOut,
  scale,
}: CubeSideProps) {
  const points = CUBE_SIDE_POINTS[side];
  const Icon = CUBE_SIDE_TO_ICON[side];
  const color = grayOut ? CUBE_SIDE_GREY_COLOR : CUBE_SIDE_TO_COLOR[side];

  function handleMouseEnter() {
    onMouseEnter(side);
  }

  function handleMouseLeave() {
    onMouseLeave(side);
  }

  function handleClick() {
    onClick(side);
  }

  return (
    <SideContainer
      id={`side-${side}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <polyline
        points={points}
        stroke={color}
        fill="#AAAAAA22"
        strokeWidth="2"
      />
      <ScalablePolyline points={points} scale={scale} color={color} />
      <Icon />
    </SideContainer>
  );
}
