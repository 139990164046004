import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { UPDATE_CURRENT_SESSION_TEST_DATA } from "../../../../../store/actions";
import { Context } from "../../../../../store/store";
import { handlePdfFileDownload, insertVariablesIntoHtml } from "./utils";
import Button from "../../components/button";
import Loader from "../../../../../components/Loader";

import "./styles.scss";

function TestResults() {
  const [state, dispatch] = useContext(Context);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const {
    publicTest: { _id, endManagerHtml, endHtml, entries, client },
    testSessionData: { isManager },
  } = state;

  const html = isManager ? endManagerHtml : endHtml;

  useEffect(() => {
    dispatch({
      type: UPDATE_CURRENT_SESSION_TEST_DATA,
      payload: { sessionId: null },
    });
  }, [dispatch]);

  const lastEntry = entries[entries.length - 1];
  const teamAttribute = lastEntry?.specialAttribute1 || lastEntry?.attribute1;

  async function handleDownloadPdfClick() {
    setIsLoading(true);
    try {
      await handlePdfFileDownload(
        lastEntry.sessionId,
        t("frontend.test.reportFileName", "sl-cube-report.pdf")
      );
    } catch {
      toast.error(
        t(
          "frontend.test.pdfExportError",
          "Error exporting PDF. Please try again later."
        )
      );
    }
    setIsLoading(false);
  }

  return (
    <div className="tg_page tg_test-results-page">
      <div className="header">
        <div
          className="tg_info"
          dangerouslySetInnerHTML={{
            __html: insertVariablesIntoHtml(html, entries),
          }}
        />
      </div>
      <div className="tg_test-report-content tg_column">
        {isManager && (
          <a
            href={`/cube-cockpit/${
              client._id
            }/${_id}/overview?key=${window.btoa(`${_id}:${teamAttribute}`)}`}
            target={"_blank"}
            rel="noreferrer"
          >
            <h2>{t("frontend.test.cockpitForTeam", "Cockpit for team")}</h2>
          </a>
        )}
        <Button onClick={handleDownloadPdfClick} large disable={isLoading}>
          {t("frontend.test.downloadPdf", "Download PDF")}
        </Button>
        {isLoading && (
          <div className="export-loader-container">
            <Loader />
          </div>
        )}
      </div>
      <div className="footer"></div>
    </div>
  );
}

export default TestResults;
