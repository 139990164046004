import { useTranslation } from "react-i18next";
import DividerIcon from "../../icons/DividerIcon";
import { ResultRow } from "../ResultRow/ResultRow";
import "./TeamChart.scss";
import { Persons } from "../Persons/Persons";
import { ValuesPerSide } from "../../hooks/useHydratedCubeCockpitData/types";
import {
  getColorFromPercentage,
  calculateAverage,
  calculateMax,
} from "../../hooks/useCubeOverviewData/utils";
import { CubeSideName } from "../Сube/types";

const Marker = ({
  percent,
  color,
  caption,
  direction,
}: {
  percent: number;
  color: string;
  caption: string;
  direction: "left" | "right";
}) => (
  <div className="marker-wrap">
    <div
      className={`${direction}-part part`}
      style={{
        bottom: `${percent}%`,
        flexDirection: direction === "left" ? "row-reverse" : "row",
      }}
    >
      <div className="marker" style={{ backgroundColor: color }}></div>
      <div className="percent" style={{ color: color }}>
        {percent}%
      </div>
      <div className="caption">{caption}</div>
    </div>
  </div>
);

const Progress = ({
  percent,
  color,
  direction,
}: {
  percent: number;
  color: string;
  direction: "left" | "right";
}) => (
  <div className="progress-wrap">
    <div
      className={`${direction}-progress`}
      style={{
        height: `calc(100% - ${percent}%`,
        color: color,
        borderLeft: direction === "left" ? `1px solid ${color}` : undefined,
        borderBottom: `1px solid ${color}`,
      }}
    >
      <div
        className={`${direction}-marker`}
        style={{ backgroundColor: color }}
      ></div>
    </div>
  </div>
);

const Bar = ({ color }: { color: string }) => (
  <div className="bar-wrap">
    <div className="bar" style={{ background: color }}></div>
  </div>
);

const ScoreSection = ({
  color,
  percent,
  caption,
  direction,
}: {
  color: string;
  percent: number;
  caption: string;
  direction: "left" | "right";
}) => (
  <div className={`${direction}-section`}>
    <div
      className="percentage"
      style={{
        color: color,
      }}
    >
      {percent}%
    </div>
    <div className="label">{caption}</div>
  </div>
);

export const TeamChart = ({
  barColor = "linear-gradient(to bottom, #3c485a, #eaeaea)",
  sideValues,
  color,
}: {
  barColor?: string;
  sideValues: Partial<Record<CubeSideName, ValuesPerSide>>;
  color?: string;
}) => {
  const { t } = useTranslation();

  const averagePercentageScore = calculateAverage(
    sideValues,
    "averagePercentageScore"
  );
  const benchmarkPercentageScore = calculateAverage(
    sideValues,
    "benchmarkPercentageScore"
  );
  const leadersCount = calculateMax(sideValues, "leadersCount");
  const teamMembersCount = calculateMax(sideValues, "teamMembersCount");
  const totalParticipantsCount = leadersCount + teamMembersCount;
  const leadersPercentageScore = calculateAverage(
    sideValues,
    "leadersPercentageScore"
  );
  const teamMembersPercentageScore = calculateAverage(
    sideValues,
    "teamMembersPercentageScore"
  );

  const averagePercentageScoreColor = getColorFromPercentage(
    averagePercentageScore
  );
  const teamMembersPercentageScoreColor = getColorFromPercentage(
    teamMembersPercentageScore
  );
  const leadersPercentageScoreColor = getColorFromPercentage(
    leadersPercentageScore
  );

  return (
    <div className="team-chart">
      <ResultRow
        text={t(
          "cubeCockpit.teamChart.benchmarkTitle",
          "Your score v Benchmark"
        )}
        percent={
          totalParticipantsCount > 0
            ? averagePercentageScore - benchmarkPercentageScore
            : null
        }
        color={color}
      />
      <div className="score-bar">
        <div className="top-chart-wrap">
          <div className="top-row">
            <ScoreSection
              direction="left"
              color={averagePercentageScoreColor}
              percent={averagePercentageScore}
              caption={t("cubeCockpit.teamChart.avgScore", "Average Score")}
            />
            <ScoreSection
              direction="right"
              color={"#3c485a"}
              percent={benchmarkPercentageScore}
              caption={t("cubeCockpit.teamChart.benchmark", "Benchmark")}
            />
          </div>
          <Persons number={totalParticipantsCount} />
          <div className="center-row">
            <Progress
              direction="left"
              percent={averagePercentageScore}
              color={averagePercentageScoreColor}
            />
            <Bar color={barColor} />
            <Progress
              direction="right"
              percent={benchmarkPercentageScore}
              color={"#3c485a"}
            />
          </div>
        </div>
        <div className="member-row">
          <Persons number={leadersCount} small noBorder />
          <div>
            <DividerIcon />
          </div>
          <Persons number={teamMembersCount} small noBorder />
        </div>
        <div className="bottom-chart">
          <Marker
            direction={"left"}
            percent={leadersPercentageScore}
            color={leadersPercentageScoreColor}
            caption={t("cubeCockpit.teamChart.leaders", "Leaders")}
          />
          <Bar color={barColor} />
          <Marker
            direction={"right"}
            percent={teamMembersPercentageScore}
            color={teamMembersPercentageScoreColor}
            caption={t("cubeCockpit.teamChart.teamMembers", "Team members")}
          />
        </div>
      </div>
      <ResultRow
        text={t(
          "cubeCockpit.teamChart.leadersTitle",
          "Leader v team perspective"
        )}
        percent={
          leadersCount
            ? leadersPercentageScore - teamMembersPercentageScore
            : null
        }
        color={color}
      />
    </div>
  );
};
