import { useMemo } from "react";
import { DtoCubeDataResponse, DtoTest } from "../@types/dto-types";

const EMPTY_ARRAY: unknown[] = [];

export function useCurrentTest(
  currentTestId: string | undefined,
  data?: DtoCubeDataResponse
) {
  return useMemo(() => {
    const tests = data?.tests || (EMPTY_ARRAY as DtoTest[]);

    return currentTestId
      ? tests.find(test => test._id === currentTestId)
      : tests[0];
  }, [currentTestId, data]);
}
