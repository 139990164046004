import {
  CUBE_SIDE_NAMES,
  CUBE_SIDE_TO_COLOR,
} from "../../components/Сube/constants";
import { CubeSideName } from "../../components/Сube/types";
import { ValuesPerSide } from "../useHydratedCubeCockpitData/types";
import { CalculatedScoresBySide, CalculationType } from "./types";

export function calculateSideValuesByAverageScore(
  valuesPerSide: Record<CubeSideName, ValuesPerSide>
) {
  return CUBE_SIDE_NAMES.reduce((acc, side) => {
    acc.percentageValue = {
      ...acc.percentageValue,
      [side]: valuesPerSide[side]?.averagePercentageScore ?? 0,
    };

    acc.cubeSideFillArea = {
      ...acc.cubeSideFillArea,
      [side]: valuesPerSide[side]?.averagePercentageScore ?? 0,
    };
    return acc;
  }, {} as CalculatedScoresBySide);
}

export function calculateSideValuesByBenchmarkScore(
  valuesPerSide: Record<CubeSideName, ValuesPerSide>
) {
  return CUBE_SIDE_NAMES.reduce((acc, side) => {
    const percentageValue =
      valuesPerSide[side].averagePercentageScore -
      valuesPerSide[side].benchmarkPercentageScore;

    acc.percentageValue = {
      ...acc.percentageValue,
      [side]: percentageValue,
    };

    acc.cubeSideFillArea = {
      ...acc.cubeSideFillArea,
      [side]: Math.min(100, 100 + percentageValue),
    };

    return acc;
  }, {} as CalculatedScoresBySide);
}

export function calculateBenchmarkPerSide(
  valuesPerSide: Record<CubeSideName, ValuesPerSide>
) {
  return CUBE_SIDE_NAMES.reduce((acc, side) => {
    acc[side] = valuesPerSide[side]?.benchmarkPercentageScore ?? 0;
    return acc;
  }, {} as Record<CubeSideName, number>);
}

export function calculateAverage(
  valuesPerSide: Partial<Record<CubeSideName, ValuesPerSide>>,
  key: keyof Omit<ValuesPerSide, "intersectionValues" | "aggregatedReport">
) {
  const values = Object.values(valuesPerSide);
  const scores = values.map(value => value?.[key]).filter(score => score > 0);
  const sum = scores.reduce((sum, score) => sum + score, 0);
  return scores.length > 0 ? Math.round(sum / scores.length) : 0;
}

export function calculateMax(
  valuesPerSide: Partial<Record<CubeSideName, ValuesPerSide>>,
  key: keyof Omit<ValuesPerSide, "intersectionValues" | "aggregatedReport">
) {
  const values = Object.values(valuesPerSide);
  const scores = values.map(value => value?.[key]);
  return Math.max(...scores);
}

export function calculateMin(
  valuesPerSide: Partial<Record<CubeSideName, ValuesPerSide>>,
  key: keyof Omit<ValuesPerSide, "intersectionValues" | "aggregatedReport">
) {
  const values = Object.values(valuesPerSide);
  const scores = values.map(value => value?.[key]);
  return Math.min(...scores);
}

export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getBarGradient = (selectedSides: CubeSideName[]) => {
  if (selectedSides.length > 3) {
    return "linear-gradient(to bottom, #3c485a, #eaeaea)";
  }

  const colors = selectedSides.map(side => CUBE_SIDE_TO_COLOR[side]);
  return `linear-gradient(180deg, ${colors[0]} 0%, ${colors[1]} 50%, ${colors[2]} 100%)`;
};

export const getColorFromPercentage = (percentage: number): string => {
  // Ensure percentage is between 0 and 100
  percentage = Math.max(0, Math.min(100, percentage));

  const colorStops: {
    color: string;
    position: number;
  }[] = [
    { color: "#e61840", position: 20 }, // Bright Red (0-20)
    { color: "#e45c4b", position: 40 }, // Coral Red (20-40)
    { color: "#ebb034", position: 60 }, // Orange/Golden (40-60)
    { color: "#aab065", position: 80 }, // Olive Green (60-80)
    { color: "#56b0ab", position: 100 }, // Turquoise (80-100)
  ];

  const hexToRgb = (hex: string): number[] => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  };

  const rgbToHex = (r: number, g: number, b: number): string => {
    return (
      "#" +
      [r, g, b]
        .map(x => Math.round(x))
        .map(x => x.toString(16).padStart(2, "0"))
        .join("")
    );
  };

  const lerp = (start: number, end: number, t: number): number => {
    return start * (1 - t) + end * t;
  };

  // Find the two colors to interpolate between
  let startColor: number[];
  let endColor: number[];
  let startPos: number;
  let endPos: number;

  // Handle the first segment (0 to first position)
  if (percentage <= colorStops[0].position) {
    startColor = hexToRgb(colorStops[0].color);
    endColor = hexToRgb(colorStops[0].color);
    startPos = 0;
    endPos = colorStops[0].position;
  } else {
    // Find the appropriate segment
    for (let i = 0; i < colorStops.length - 1; i++) {
      if (percentage <= colorStops[i + 1].position) {
        startColor = hexToRgb(colorStops[i].color);
        endColor = hexToRgb(colorStops[i + 1].color);
        startPos = colorStops[i].position;
        endPos = colorStops[i + 1].position;
        break;
      }
    }
  }

  // Calculate interpolation factor
  const t = (percentage - startPos!) / (endPos! - startPos!);

  // Interpolate each RGB component
  const r = lerp(startColor![0], endColor![0], t);
  const g = lerp(startColor![1], endColor![1], t);
  const b = lerp(startColor![2], endColor![2], t);

  return rgbToHex(r, g, b);
};

export function getAverageOfSides(
  valuesPerSide?: Record<CubeSideName, ValuesPerSide>
) {
  if (!valuesPerSide) {
    return {
      [CalculationType.Benchmark]: calculateSideValuesByAverageScore(
        {} as Record<CubeSideName, ValuesPerSide>
      ),
      [CalculationType.AverageScore]: calculateSideValuesByAverageScore(
        {} as Record<CubeSideName, ValuesPerSide>
      ),
      averageScore: 0,
      averageBenchmark: 0,
      benchmarkPerSidePercentageScore: calculateBenchmarkPerSide(
        {} as Record<CubeSideName, ValuesPerSide>
      ),
    };
  }

  return {
    [CalculationType.Benchmark]:
      calculateSideValuesByBenchmarkScore(valuesPerSide),
    [CalculationType.AverageScore]:
      calculateSideValuesByAverageScore(valuesPerSide),
    averageScore: calculateAverage(valuesPerSide, "averagePercentageScore"),
    averageBenchmark: calculateAverage(
      valuesPerSide,
      "benchmarkPercentageScore"
    ),
    benchmarkPerSidePercentageScore: calculateBenchmarkPerSide(valuesPerSide),
  };
}
