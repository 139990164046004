import {
  CUBE_SIDE_NAME_TO_NUMBER_MAP,
  CUBE_SIDE_NAMES,
  CUBE_SIDES_INTERSECTIONS,
} from "../../components/Сube/constants";
import { CubeSideName } from "../../components/Сube/types";
import { DtoTestSet, DtoEntry, DtoTestSetScale } from "../../@types/dto-types";
import { Category, IntersectionValue, ValuesPerSide } from "./types";
import { SelectedFilters } from "../useFilters";
import { getAggregatedItemsReport } from "./reports-utils";

export function groupTestSetsByCubeSide(testSets: DtoTestSet[]) {
  return CUBE_SIDE_NAMES.reduce((acc, sideName) => {
    acc[sideName] = testSets.filter(
      testSet =>
        parseInt(testSet.info.cubeSide) ===
        CUBE_SIDE_NAME_TO_NUMBER_MAP[sideName]
    );
    return acc;
  }, {} as Record<CubeSideName, DtoTestSet[]>);
}

export function groupEntriesByCubeSide(
  testSetsByCubeSide: Record<CubeSideName, DtoTestSet[]>,
  allEntries: DtoEntry[]
) {
  return CUBE_SIDE_NAMES.reduce((acc, sideName) => {
    const testSetIds = testSetsByCubeSide[sideName].map(testSet => testSet._id);
    const sideEntries = allEntries.filter(entry =>
      testSetIds.includes(entry.testSetId)
    );
    return {
      ...acc,
      [sideName]: sideEntries,
    };
  }, {} as Record<CubeSideName, DtoEntry[]>);
}

function getValueKeysByCondition<T>(
  items: T[],
  condition: (item: T) => boolean,
  getKey: (item: T, index: number) => string
): string[] {
  return items.reduce((acc, item, i) => {
    if (condition(item)) {
      acc.push(getKey(item, i));
    }
    return acc;
  }, [] as string[]);
}

export function getScalesValueKeysByIntersectionNum(
  scales: DtoTestSetScale[],
  intersection: number
) {
  return getValueKeysByCondition(
    scales,
    scale => scale.intersection === intersection,
    (_scale, i) => `S${i + 1}V`
  ) as `S${number}V`[];
}

export function getScalesValueKeysByCategory(
  scales: DtoTestSetScale[],
  category: Category
) {
  return getValueKeysByCondition(
    scales,
    scale => scale.category === category,
    (_scale, i) => `S${i + 1}V`
  ) as `S${number}V`[];
}

export function getScalesByCategory(
  scales: DtoTestSetScale[],
  category: Category
) {
  return scales.filter(scale => scale.category === category);
}

export function normalizeValueToPercentage(value: number) {
  const normalized = ((value - 1) / 5) * 100;
  return normalized > 0 ? normalized : 0;
}

function getIntersectionAverageValue(
  side: CubeSideName,
  intersection: number,
  testSetsByCubeSide: Record<CubeSideName, DtoTestSet[]>,
  entriesGroupedBySide: Record<CubeSideName, DtoEntry[]>
): number {
  const testSet = testSetsByCubeSide[side][0];
  const entries = entriesGroupedBySide[side];
  if (!testSet || !entries?.length) {
    return 0;
  }
  const [intersectionValueKey] = getScalesValueKeysByIntersectionNum(
    testSet.scales,
    intersection
  );
  const sum = entries.reduce(
    (acc, entry) => acc + entry[intersectionValueKey],
    0
  );
  return sum / entries.length;
}

export function getIntersectionValues(
  side: CubeSideName,
  testSetsByCubeSide: Record<CubeSideName, DtoTestSet[]>,
  entriesGroupedBySide: Record<CubeSideName, DtoEntry[]>
): IntersectionValue[] {
  return CUBE_SIDES_INTERSECTIONS[side].map(otherSideIntersection => {
    const { side: otherSide, intersection } = otherSideIntersection;
    const currentAvg = getIntersectionAverageValue(
      side,
      intersection,
      testSetsByCubeSide,
      entriesGroupedBySide
    );
    const otherAvg = getIntersectionAverageValue(
      otherSide,
      intersection,
      testSetsByCubeSide,
      entriesGroupedBySide
    );
    const currentPercentage = normalizeValueToPercentage(currentAvg);
    const otherPercentage = normalizeValueToPercentage(otherAvg);
    let percentageValue =
      currentAvg > 0 && otherAvg > 0
        ? (currentPercentage + otherPercentage) / 2
        : currentPercentage || otherPercentage;

    return {
      side: otherSide,
      percentageValue,
    };
  });
}

export function getValuesPerSide(
  side: CubeSideName,
  testSetsByCubeSide: Record<CubeSideName, DtoTestSet[]>,
  entriesGroupedBySide: Record<CubeSideName, DtoEntry[]>,
  currentTestId: string
): ValuesPerSide {
  const dataAccumulator: ValuesPerSide = {
    teamMembersCount: 0,
    leadersCount: 0,
    totalCount: 0,
    teamMembersPercentageScore: 0,
    leadersPercentageScore: 0,
    averagePercentageScore: 0,
    benchmarkPercentageScore: 0,
    implementationQualityPercentage: 0,
    importancePercentage: 0,
    intersectionValues: [],
    aggregatedReport: null,
  };

  const currentTestSet = testSetsByCubeSide[side][0];

  if (currentTestSet) {
    const [consentScale] = getScalesByCategory(
      currentTestSet.scales,
      Category.Consent
    );
    const [consentScaleKey] = getScalesValueKeysByCategory(
      currentTestSet.scales,
      Category.Consent
    );
    const [qualityScaleKey] = getScalesValueKeysByCategory(
      currentTestSet.scales,
      Category.Quality
    );
    const [importanceScaleKey] = getScalesValueKeysByCategory(
      currentTestSet.scales,
      Category.Importance
    );

    dataAccumulator.benchmarkPercentageScore = normalizeValueToPercentage(
      consentScale.benchmark
    );

    const currentEntries = entriesGroupedBySide[side];
    currentEntries.forEach(entry => {
      const consentValue = entry[consentScaleKey];
      dataAccumulator.averagePercentageScore += consentValue;
      dataAccumulator.totalCount += 1;

      if (entry.isManager) {
        dataAccumulator.leadersCount++;
        dataAccumulator.leadersPercentageScore += consentValue;
        dataAccumulator.implementationQualityPercentage +=
          entry[qualityScaleKey];
        dataAccumulator.importancePercentage += entry[importanceScaleKey];
      } else {
        dataAccumulator.teamMembersCount++;
        dataAccumulator.teamMembersPercentageScore += consentValue;
      }
    });

    dataAccumulator.leadersPercentageScore = normalizeValueToPercentage(
      dataAccumulator.leadersPercentageScore / dataAccumulator.leadersCount
    );
    dataAccumulator.teamMembersPercentageScore = normalizeValueToPercentage(
      dataAccumulator.teamMembersPercentageScore /
        dataAccumulator.teamMembersCount
    );
    dataAccumulator.averagePercentageScore = normalizeValueToPercentage(
      dataAccumulator.averagePercentageScore / dataAccumulator.totalCount
    );
    dataAccumulator.implementationQualityPercentage =
      normalizeValueToPercentage(
        dataAccumulator.implementationQualityPercentage /
          dataAccumulator.leadersCount
      );
    dataAccumulator.importancePercentage = normalizeValueToPercentage(
      dataAccumulator.importancePercentage / dataAccumulator.leadersCount
    );
  }

  dataAccumulator.intersectionValues = getIntersectionValues(
    side,
    testSetsByCubeSide,
    entriesGroupedBySide
  );

  dataAccumulator.aggregatedReport = getAggregatedItemsReport(
    currentTestId,
    side,
    testSetsByCubeSide,
    entriesGroupedBySide
  );

  return dataAccumulator;
}

export function filterEntriesByAttributes(
  entries: DtoEntry[],
  selectedFilters: SelectedFilters
) {
  return entries.filter(entry => {
    return Object.entries(selectedFilters).every(([key, values]) => {
      if (values.length === 0) {
        return true;
      }

      return values.includes(entry[key as keyof DtoEntry]);
    });
  });
}
