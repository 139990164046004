import { CUBE_COCKPIT_URL } from "../constants";
import { NavItem } from "./types";

export const CUBE_COCKPIT_URL_PATTERN = `${CUBE_COCKPIT_URL}/:clientId/:testId`;

export const CUBE_SIDES_NAV_ROUTES: NavItem[] = [
  "leader",
  "team",
  "vision",
  "strategy",
  "structures",
  "kpis",
];
export const CUBE_OVERVIEW_NAV_ROUTES: NavItem[] = [
  "overview",
  "soft-factors",
  "hard-factors",
];
