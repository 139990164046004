export const ENTRIES_URL = "/tool/entries";
export const LOGIN_URL = "/tool/login";

export const TESTS_URL = "/tool/tests";
export const ADD_TEST_URL = "/tool/tests/new";

export const REPORT_URL = "/tool/reports";
export const ADD_REPORT_URL = "/tool/reports/new";

export const GROUP_URL = "/tool/groups";
export const ADD_GROUP_URL = "/tool/groups/new";

export const CLIENTS_URL = "/tool/clients";
export const ADD_CLIENT_URL = "/tool/clients/new";

export const FILTER_BY_CLIENT = {
  [ENTRIES_URL]: true,
  [TESTS_URL]: true,
  [REPORT_URL]: true,
  [GROUP_URL]: true,
};

export function initiateClientsMap(t) {
  return new Map([
    [undefined, t("tool.clientSelector.all", "All clients")],
    ["null", t("tool.clientSelector.none", "No client")],
  ]);
}

export const TEST_SETS_COUNT_LIMIT = 6;
